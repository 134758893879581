import React, { useRef, useState, useContext, useEffect } from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';
import xml2js from 'xml2js';

import { DialogoConfirmacao } from '../../../..';
import { api } from '../../../../../services';
import {
  formatDateDDMMYYYYHHmm,
  formatCNPJ,
  formatCPF,
  formatIE,
  formatMoeda,
  formatDateDDMMYYYY,
  round10,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
} from '../../../../../utils';
import { useItens } from '../../../DialogoDevolucao/hooks';
import { CompraContext } from '../contextCompra';
import AppBar from '../ImportacaoXML/AppBar';
import DialogoPassadorLotes from '../ImportacaoXML/DialogoPassadorLotes';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
}))(TableCell);

const FECHAR_COMPRA_ACTION = 'F8';
const CANCELAR_COMPRA_ACTION = 'F4';

const codigosTipoOperacao = {
  0: 'Entrada',
  1: 'Saída',
};

const codigosIdentificacaoDoAmbiente = {
  1: 'Produção',
  2: 'Homologação',
};

const codigosFinalidadeDaEmissaoDaNfe = {
  1: 'NF-e normal;',
  2: 'NF-e complementar;',
  3: 'NF-e de ajuste;',
  4: 'Devolução de mercadoria.',
};

const codigosIndicadorFormaDePagamento = {
  1: 'Pagamento à Vista',
  2: 'Pagamento à Prazo',
};

const codigosMeiosDePagamento = {
  '01': 'Dinheiro',
  '02': 'Cheque',
  '03': 'Cartão de Crédito',
  '04': 'Cartão de Débito',
  '05': 'Crédito Loja',
  10: 'Vale Alimentação',
  11: 'Vale Refeição',
  12: 'Vale Presente',
  13: 'Vale Combustível',
  15: 'Boleto Bancário',
  16: 'Depósito Bancário',
  17: 'Pagamento Instantâneo (PIX)',
  18: 'Transferência bancária, Carteira Digital',
  19: 'Programa de fidelidade, Cashback, Crédito Virtual',
  90: 'Sem pagamento',
  99: 'Outros',
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  recarregar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: 'black',
    backgroundColor: 'white',
    cursor: 'pointer',
    opacity: '0.7',
    '&:hover': {
      opacity: '1',
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getPrecosOfertas(produto) {
  let precoOfertaJornal = 0;
  let precoOfertaZerarEstoque = 0;

  if (produto.ofertasProdutos.length > 0) {
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo === TIPO_OFERTA_PRODUTO_JORNAL) {
        precoOfertaJornal = oferta.valor;
      } else if (oferta.tipo === TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE) {
        precoOfertaZerarEstoque = oferta.valor;
      }
    }
  }

  return {
    precoOfertaJornal,
    precoOfertaZerarEstoque,
  };
}

function TipoCompra({
  cancelarCompra,
  irParaTelaFrentedeCaixa,
  chaveDeAcesso,
}) {
  const refDialogoConfirmacao = useRef(null);
  const refInputFile = useRef(null);
  const analisando = useRef(false);
  const [dadosXML, setDadosXML] = useState(null);
  const [value, setValue] = useState(0);
  const [dadosServidor, setDadosServidor] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { compra, dispatch } = useContext(CompraContext);
  const { config } = compra;

  const [carregando, setCarregando] = useState(false);

  const refDialogoPassadorLotes = useRef(null);

  const classes = useStyles();

  function format10(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  function formatDate(date) {
    if (date) {
      const dateReal = new Date(date);
      const ano = dateReal.getFullYear();
      const mes = format10(dateReal.getMonth() + 1);
      const dia = format10(dateReal.getDate());
      return `${ano}-${mes}-${dia}`;
    }
    return '';
  }

  function montarDadosCompra2(lotes, parcelas) {
    return {
      idEdit: -1,
      lotes,
      parcelas,
      fornecedor: dadosServidor ? dadosServidor.fornecedor : null,
      numeroNotaFiscal: dadosXML ? dadosXML.nNF.value : '',
      data_faturamento: dadosXML ? formatDate(dadosXML.dhEmi.value) : '',
      data_criacao: new Date(),
      loja: {
        nome: 'CARUARU FRIOS',
        value: 'CF',
      },
      repasse_ipi: totalRepasseIpiCalculado === 0 ? totalRepasseIpiXML : 0,
      repasse_frete:
        totalRepasseFreteCalculado === 0 ? totalRepasseFreteXML : 0,
      repasse_st: totalRepasseSTCalculado === 0 ? totalRepasseSTXML : 0,
      repasse_seguros:
        totalRepasseSeguroCalculado === 0 ? totalRepasseSeguroXML : 0,
      repasse_outras_despesas:
        totalRepasseOutrasDespesasCalculado === 0
          ? totalRepasseOutrasDespesasXML
          : 0,
      carregando: false,
      config,
      numeroCompra: '-',
      isProdutosSomenteFornecedor: true,
      isIncluiInativos: false,
      serieNotaFiscal: dadosXML ? dadosXML.serie.value : '',
      chaveAcessoNotaFiscal: dadosXML ? dadosXML.Id.value : '',
      isImportacaoXml: true,
    };
  }

  function handleConcluido(lotes, parcelas) {
    const dadosCompra = montarDadosCompra2(lotes, parcelas);
    dispatch({
      type: 'PREENCHER_TUDO',
      ...dadosCompra,
    });
    irParaTelaFrentedeCaixa();
  }

  function dadosParcelas(xml) {
    const pag = [];
    const fat = null;
    const dup = [];

    if (!xml) {
      return {
        pag,
        fat,
        dup,
      };
    }

    return {
      pag: xml.pagamentos,
      fat: xml.fat,
      dup: xml.parcelas,
    };
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_COMPRA_ACTION: {
        if (config) {
          if (refDialogoPassadorLotes.current) {
            refDialogoPassadorLotes.current.handleOpen(
              totalRepasseIpiXML !== totalRepasseIpiCalculado,
              totalRepasseFreteXML !== totalRepasseFreteCalculado,
              totalRepasseSTXML !== totalRepasseSTCalculado,
              totalRepasseSeguroXML !== totalRepasseSeguroCalculado,
              totalRepasseOutrasDespesasXML !==
                totalRepasseOutrasDespesasCalculado,
              dadosServidor ? dadosServidor.fornecedor : null,
              config,
              itensFormatados,
              dadosParcelas(dadosXML),
            );
          }
        } else {
          enqueueSnackbar('Configurações ainda não carregadas', {
            variant: 'error',
          });
        }
        break;
      }
      case CANCELAR_COMPRA_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Cancelamento da compra',
            'Tem certeza que deseja cancelar essa compra!',
            CANCELAR_COMPRA_ACTION,
          );
        }
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_COMPRA_ACTION:
        cancelarCompra();
        break;
      default:
        break;
    }
  }

  function preencherComZeros(value, tam) {
    let valueFormatado = `${value}`;

    for (let i = 0; i < tam - value.length; i++) {
      valueFormatado = `0${valueFormatado}`;
    }

    return valueFormatado;
  }

  function getDadosUteisXml(dados) {
    const dadosFormatados = {
      remetente: {},
      destinatario: {},
      itens: [],
      parcelas: [],
      pagamentos: [],
      totalRepasseFreteCalculado: 0,
      totalRepasseSeguroCalculado: 0,
      totalRepasseOutrasDespesasCalculado: 0,
      totalRepasseIpiCalculado: 0,
      totalRepasseSTCalculado: 0,
    };
    if (dados) {
      const nfeProc = dados.nfeProc;
      if (nfeProc) {
        if (nfeProc.NFe) {
          if (nfeProc.NFe.length > 0) {
            const NFe = nfeProc.NFe[0];
            if (NFe.infNFe) {
              if (NFe.infNFe.length > 0) {
                const infNFe = NFe.infNFe[0];

                // Id
                if (infNFe.Id) {
                  if (infNFe.Id.length > 0) {
                    dadosFormatados.Id = {
                      descricao: `Chave de Acesso`,
                      value: infNFe.Id[0].replace('NFe', ''),
                      valorFormatado: infNFe.Id[0].replace('NFe', ''),
                    };
                  }
                }

                // ide
                if (infNFe.ide) {
                  if (infNFe.ide.length > 0) {
                    const ide = infNFe.ide[0];

                    // serie
                    if (ide.serie) {
                      if (ide.serie.length > 0) {
                        dadosFormatados.serie = {
                          descricao: 'Série do Documento Fiscal',
                          value: preencherComZeros(ide.serie[0], 3),
                          valorFormatado: preencherComZeros(ide.serie[0], 3),
                        };
                      }
                    }

                    // nNF
                    if (ide.nNF) {
                      if (ide.nNF.length > 0) {
                        dadosFormatados.nNF = {
                          descricao: 'Número do Documento Fiscal',
                          value: preencherComZeros(ide.nNF[0], 9),
                          valorFormatado: preencherComZeros(ide.nNF[0], 9),
                        };
                      }
                    }

                    // dhEmi
                    if (ide.dhEmi) {
                      if (ide.dhEmi.length > 0) {
                        dadosFormatados.dhEmi = {
                          descricao:
                            'Data e hora de emissão do Documento Fiscal',
                          value: new Date(ide.dhEmi[0]),
                          valorFormatado: formatDateDDMMYYYYHHmm(
                            new Date(ide.dhEmi[0]),
                          ),
                        };
                      }
                    }

                    // tpNF
                    if (ide.tpNF) {
                      if (ide.tpNF.length > 0) {
                        dadosFormatados.tpNF = {
                          descricao: 'Tipo de Operação',
                          value: ide.tpNF[0],
                          valorFormatado: codigosTipoOperacao[ide.tpNF[0]],
                        };
                      }
                    }

                    // tpAmb
                    if (ide.tpAmb) {
                      if (ide.tpAmb.length > 0) {
                        dadosFormatados.tpAmb = {
                          descricao: 'Identificação do Ambiente',
                          value: ide.tpAmb[0],
                          valorFormatado:
                            codigosIdentificacaoDoAmbiente[ide.tpAmb[0]],
                        };
                      }
                    }

                    // finNFe
                    if (ide.finNFe) {
                      if (ide.finNFe.length > 0) {
                        dadosFormatados.finNFe = {
                          descricao: 'Finalidade de emissão da NF-e',
                          value: ide.finNFe[0],
                          valorFormatado:
                            codigosFinalidadeDaEmissaoDaNfe[ide.finNFe[0]],
                        };
                      }
                    }
                  }
                }

                //

                if (infNFe.emit) {
                  if (infNFe.emit.length > 0) {
                    const emit = infNFe.emit[0];

                    // CNPJ
                    if (emit.CNPJ) {
                      if (emit.CNPJ.length > 0) {
                        dadosFormatados.remetente.CNPJ = {
                          descricao: 'CNPJ do emitente',
                          value: emit.CNPJ[0],
                          valorFormatado: formatCNPJ(emit.CNPJ[0]),
                        };
                      }
                    }

                    // CPF
                    if (emit.CPF) {
                      if (emit.CPF.length > 0) {
                        dadosFormatados.remetente.CPF = {
                          descricao: 'CPF do remetente',
                          value: emit.CPF[0],
                          valorFormatado: formatCPF(emit.CPF[0]),
                        };
                      }
                    }

                    // xNome
                    if (emit.xNome) {
                      if (emit.xNome.length > 0) {
                        dadosFormatados.remetente.xNome = {
                          descricao: 'Razão Social ou Nome do emitente',
                          value: emit.xNome[0],
                          valorFormatado: emit.xNome[0],
                        };
                      }
                    }

                    // xFant
                    if (emit.xFant) {
                      if (emit.xFant.length > 0) {
                        dadosFormatados.remetente.xFant = {
                          descricao: 'Nome fantasia',
                          value: emit.xFant[0],
                          valorFormatado: emit.xFant[0],
                        };
                      }
                    }

                    // IE
                    if (emit.IE) {
                      if (emit.IE.length > 0) {
                        dadosFormatados.remetente.IE = {
                          descricao: 'Inscrição Estadual do Emitente',
                          value: emit.IE[0],
                          valorFormatado: formatIE(emit.IE[0]),
                        };
                      }
                    }

                    // CRT
                    if (emit.CRT) {
                      if (emit.CRT.length > 0) {
                        dadosFormatados.remetente.CRT = {
                          descricao: 'Código de Regime Tributário',
                          value: emit.CRT[0],
                          valorFormatado: emit.CRT[0],
                        };
                      }
                    }

                    // enderEmit
                    if (emit.enderEmit) {
                      if (emit.enderEmit.length > 0) {
                        const enderEmit = emit.enderEmit[0];

                        // xLgr
                        if (enderEmit.xLgr) {
                          if (enderEmit.xLgr.length > 0) {
                            dadosFormatados.remetente.xLgr = {
                              descricao: 'Logradouro',
                              value: enderEmit.xLgr[0],
                              valorFormatado: enderEmit.xLgr[0],
                            };
                          }
                        }

                        // nro
                        if (enderEmit.nro) {
                          if (enderEmit.nro.length > 0) {
                            dadosFormatados.remetente.nro = {
                              descricao: 'Número',
                              value: enderEmit.nro[0],
                              valorFormatado: enderEmit.nro[0],
                            };
                          }
                        }

                        // xCpl
                        if (enderEmit.xCpl) {
                          if (enderEmit.xCpl.length > 0) {
                            dadosFormatados.remetente.xCpl = {
                              descricao: 'Complemento',
                              value: enderEmit.xCpl[0],
                              valorFormatado: enderEmit.xCpl[0],
                            };
                          }
                        }

                        // xBairro
                        if (enderEmit.xBairro) {
                          if (enderEmit.xBairro.length > 0) {
                            dadosFormatados.remetente.xBairro = {
                              descricao: 'Bairro',
                              value: enderEmit.xBairro[0],
                              valorFormatado: enderEmit.xBairro[0],
                            };
                          }
                        }

                        // cMun
                        if (enderEmit.cMun) {
                          if (enderEmit.cMun.length > 0) {
                            dadosFormatados.remetente.cMun = {
                              descricao: 'Código do município',
                              value: enderEmit.cMun[0],
                              valorFormatado: enderEmit.cMun[0],
                            };
                          }
                        }

                        // xMun
                        if (enderEmit.xMun) {
                          if (enderEmit.xMun.length > 0) {
                            dadosFormatados.remetente.xMun = {
                              descricao: 'Nome do município',
                              value: enderEmit.xMun[0],
                              valorFormatado: enderEmit.xMun[0],
                            };
                          }
                        }

                        // UF
                        if (enderEmit.UF) {
                          if (enderEmit.UF.length > 0) {
                            dadosFormatados.remetente.UF = {
                              descricao: 'Sigla da UF',
                              value: enderEmit.UF[0],
                              valorFormatado: enderEmit.UF[0],
                            };
                          }
                        }

                        // CEP
                        if (enderEmit.CEP) {
                          if (enderEmit.CEP.length > 0) {
                            dadosFormatados.remetente.CEP = {
                              descricao: 'Código do CEP',
                              value: enderEmit.CEP[0],
                              valorFormatado: enderEmit.CEP[0],
                            };
                          }
                        }

                        // fone
                        if (enderEmit.fone) {
                          if (enderEmit.fone.length > 0) {
                            dadosFormatados.remetente.fone = {
                              descricao: 'Telefone',
                              value: enderEmit.fone[0],
                              valorFormatado: enderEmit.fone[0],
                            };
                          }
                        }
                      }
                    }
                  }
                }

                ///

                if (infNFe.dest) {
                  if (infNFe.dest.length > 0) {
                    const dest = infNFe.dest[0];

                    // CNPJ
                    if (dest.CNPJ) {
                      if (dest.CNPJ.length > 0) {
                        dadosFormatados.destinatario.CNPJ = {
                          descricao: 'CNPJ do destinatário',
                          value: dest.CNPJ[0],
                          valorFormatado: formatCNPJ(dest.CNPJ[0]),
                        };
                      }
                    }

                    // CPF
                    if (dest.CPF) {
                      if (dest.CPF.length > 0) {
                        dadosFormatados.destinatario.CPF = {
                          descricao: 'CNPJ do destinatário',
                          value: dest.CPF[0],
                          valorFormatado: formatCPF(dest.CPF[0]),
                        };
                      }
                    }

                    // xNome
                    if (dest.xNome) {
                      if (dest.xNome.length > 0) {
                        dadosFormatados.destinatario.xNome = {
                          descricao: 'Razão Social ou nome do destinatário',
                          value: dest.xNome[0],
                          valorFormatado: dest.xNome[0],
                        };
                      }
                    }

                    // IE
                    if (dest.IE) {
                      if (dest.IE.length > 0) {
                        dadosFormatados.destinatario.IE = {
                          descricao: 'Inscrição Estadual do Destinatário',
                          value: dest.IE[0],
                          valorFormatado: dest.IE[0],
                        };
                      }
                    }

                    // email
                    if (dest.email) {
                      if (dest.email.length > 0) {
                        dadosFormatados.destinatario.email = {
                          descricao: 'email',
                          value: dest.email[0],
                          valorFormatado: dest.email[0],
                        };
                      }
                    }

                    // enderEmit
                    if (dest.enderDest) {
                      if (dest.enderDest.length > 0) {
                        const enderDest = dest.enderDest[0];

                        // xLgr
                        if (enderDest.xLgr) {
                          if (enderDest.xLgr.length > 0) {
                            dadosFormatados.destinatario.xLgr = {
                              descricao: 'Logradouro',
                              value: enderDest.xLgr[0],
                              valorFormatado: enderDest.xLgr[0],
                            };
                          }
                        }

                        // nro
                        if (enderDest.nro) {
                          if (enderDest.nro.length > 0) {
                            dadosFormatados.destinatario.nro = {
                              descricao: 'Número',
                              value: enderDest.nro[0],
                              valorFormatado: enderDest.nro[0],
                            };
                          }
                        }

                        // xCpl
                        if (enderDest.xCpl) {
                          if (enderDest.xCpl.length > 0) {
                            dadosFormatados.destinatario.xCpl = {
                              descricao: 'Complemento',
                              value: enderDest.xCpl[0],
                              valorFormatado: enderDest.xCpl[0],
                            };
                          }
                        }

                        // xBairro
                        if (enderDest.xBairro) {
                          if (enderDest.xBairro.length > 0) {
                            dadosFormatados.destinatario.xBairro = {
                              descricao: 'Bairro',
                              value: enderDest.xBairro[0],
                              valorFormatado: enderDest.xBairro[0],
                            };
                          }
                        }

                        // cMun
                        if (enderDest.cMun) {
                          if (enderDest.cMun.length > 0) {
                            dadosFormatados.destinatario.cMun = {
                              descricao: 'Código do município',
                              value: enderDest.cMun[0],
                              valorFormatado: enderDest.cMun[0],
                            };
                          }
                        }

                        // xMun
                        if (enderDest.xMun) {
                          if (enderDest.xMun.length > 0) {
                            dadosFormatados.destinatario.xMun = {
                              descricao: 'Nome do município',
                              value: enderDest.xMun[0],
                              valorFormatado: enderDest.xMun[0],
                            };
                          }
                        }

                        // UF
                        if (enderDest.UF) {
                          if (enderDest.UF.length > 0) {
                            dadosFormatados.destinatario.UF = {
                              descricao: 'Sigla da UF',
                              value: enderDest.UF[0],
                              valorFormatado: enderDest.UF[0],
                            };
                          }
                        }

                        // CEP
                        if (enderDest.CEP) {
                          if (enderDest.CEP.length > 0) {
                            dadosFormatados.destinatario.CEP = {
                              descricao: 'Código do CEP',
                              value: enderDest.CEP[0],
                              valorFormatado: enderDest.CEP[0],
                            };
                          }
                        }

                        // cPais
                        if (enderDest.cPais) {
                          if (enderDest.cPais.length > 0) {
                            dadosFormatados.destinatario.cPais = {
                              descricao: 'Código do País',
                              value: enderDest.cPais[0],
                              valorFormatado: enderDest.cPais[0],
                            };
                          }
                        }

                        // xPais
                        if (enderDest.xPais) {
                          if (enderDest.xPais.length > 0) {
                            dadosFormatados.destinatario.xPais = {
                              descricao: 'Nome do País',
                              value: enderDest.xPais[0],
                              valorFormatado: enderDest.xPais[0],
                            };
                          }
                        }

                        // fone
                        if (enderDest.fone) {
                          if (enderDest.fone.length > 0) {
                            dadosFormatados.destinatario.fone = {
                              descricao: 'Telefone',
                              value: enderDest.fone[0],
                              valorFormatado: enderDest.fone[0],
                            };
                          }
                        }
                      }
                    }
                  }
                }

                ///////////

                if (infNFe.det) {
                  const dets = infNFe.det;

                  for (let i = 0; i < dets.length; i++) {
                    const det = dets[i];
                    const detFormatados = {};

                    // nItem
                    if (det.nItem) {
                      if (det.nItem.length > 0) {
                        detFormatados.nItem = {
                          descricao: 'Número do item',
                          value: det.nItem[0],
                          valorFormatado: det.nItem[0],
                        };
                      }
                    }

                    // prod
                    if (det.prod) {
                      if (det.prod.length > 0) {
                        const prod = det.prod[0];

                        // cProd
                        if (prod.cProd) {
                          if (prod.cProd.length > 0) {
                            detFormatados.cProd = {
                              descricao: 'Código do produto ou serviço',
                              value: prod.cProd[0],
                              valorFormatado: prod.cProd[0],
                            };
                          }
                        }

                        // cEAN
                        if (prod.cEAN) {
                          if (prod.cEAN.length > 0) {
                            detFormatados.cEAN = {
                              descricao:
                                'GTIN (Global Trade Item Number) do produto, antigo código EAN ou código de barras',
                              value: prod.cEAN[0],
                              valorFormatado: prod.cEAN[0],
                            };
                          }
                        }

                        // xProd
                        if (prod.xProd) {
                          if (prod.xProd.length > 0) {
                            detFormatados.xProd = {
                              descricao: 'Descrição do produto ou serviço',
                              value: prod.xProd[0]
                                .trim()
                                .replace(/\s{2,}/g, ' '),
                              valorFormatado: prod.xProd[0]
                                .trim()
                                .replace(/\s{2,}/g, ' '),
                            };
                          }
                        }

                        // NCM
                        if (prod.NCM) {
                          if (prod.NCM.length > 0) {
                            detFormatados.NCM = {
                              descricao: 'Código NCM com 8 dígitos ',
                              value: prod.NCM[0],
                              valorFormatado: prod.NCM[0],
                            };
                          }
                        }

                        // NVE
                        if (prod.NVE) {
                          if (prod.NVE.length > 0) {
                            detFormatados.NVE = {
                              descricao:
                                'Codificação NVE - Nomenclatura de Valor Aduaneiro e Estatística.',
                              value: prod.NVE[0],
                              valorFormatado: prod.NVE[0],
                            };
                          }
                        }

                        // CEST
                        if (prod.CEST) {
                          if (prod.CEST.length > 0) {
                            detFormatados.CEST = {
                              descricao: 'Código CEST',
                              value: prod.CEST[0],
                              valorFormatado: prod.CEST[0],
                            };
                          }
                        }

                        // indEscala
                        if (prod.indEscala) {
                          if (prod.indEscala.length > 0) {
                            detFormatados.indEscala = {
                              descricao: 'Indicador de Escala Relevante',
                              value: prod.indEscala[0],
                              valorFormatado: prod.indEscala[0],
                            };
                          }
                        }

                        // CNPJFab
                        if (prod.CNPJFab) {
                          if (prod.CNPJFab.length > 0) {
                            detFormatados.CNPJFab = {
                              descricao: 'CNPJ do Fabricante da Mercadoria',
                              value: prod.CNPJFab[0],
                              valorFormatado: prod.CNPJFab[0],
                            };
                          }
                        }

                        // cBenef
                        if (prod.cBenef) {
                          if (prod.cBenef.length > 0) {
                            detFormatados.cBenef = {
                              descricao:
                                'Código de Benefício Fiscal na UF aplicado ao item',
                              value: prod.cBenef[0],
                              valorFormatado: prod.cBenef[0],
                            };
                          }
                        }

                        // EXTIPI
                        if (prod.EXTIPI) {
                          if (prod.EXTIPI.length > 0) {
                            detFormatados.EXTIPI = {
                              descricao: 'EX_TIPI',
                              value: prod.EXTIPI[0],
                              valorFormatado: prod.EXTIPI[0],
                            };
                          }
                        }

                        // CFOP
                        if (prod.CFOP) {
                          if (prod.CFOP.length > 0) {
                            detFormatados.CFOP = {
                              descricao:
                                'Código Fiscal de Operações e Prestações',
                              value: prod.CFOP[0],
                              valorFormatado: prod.CFOP[0],
                            };
                          }
                        }

                        // uCom
                        if (prod.uCom) {
                          if (prod.uCom.length > 0) {
                            detFormatados.uCom = {
                              descricao: 'Unidade Comercial ',
                              value: prod.uCom[0],
                              valorFormatado: prod.uCom[0],
                            };
                          }
                        }

                        // qCom
                        if (prod.qCom) {
                          if (prod.qCom.length > 0) {
                            detFormatados.qCom = {
                              descricao: 'Quantidade Comercial',
                              value: Number(prod.qCom[0]),
                              valorFormatado: prod.qCom[0],
                            };
                          }
                        }

                        // vUnCom
                        if (prod.vUnCom) {
                          if (prod.vUnCom.length > 0) {
                            detFormatados.vUnCom = {
                              descricao: 'Valor Unitário de Comercialização',
                              value: Number(prod.vUnCom[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vUnCom[0]),
                              ),
                            };
                          }
                        }

                        // vProd
                        if (prod.vProd) {
                          if (prod.vProd.length > 0) {
                            detFormatados.vProd = {
                              descricao:
                                'Valor Total Bruto dos Produtos ou Serviços.',
                              value: Number(prod.vProd[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vProd[0]),
                              ),
                            };
                          }
                        }

                        // cEANTrib
                        if (prod.cEANTrib) {
                          if (prod.cEANTrib.length > 0) {
                            detFormatados.cEANTrib = {
                              descricao:
                                'GTIN (Global Trade Item Number) da unidade tributável, antigo código EAN ou código de barras',
                              value: prod.cEANTrib[0],
                              valorFormatado: prod.cEANTrib[0],
                            };
                          }
                        }

                        // uTrib
                        if (prod.uTrib) {
                          if (prod.uTrib.length > 0) {
                            detFormatados.uTrib = {
                              descricao: 'Unidade Tributável',
                              value: prod.uTrib[0],
                              valorFormatado: prod.uTrib[0],
                            };
                          }
                        }

                        // qTrib
                        if (prod.qTrib) {
                          if (prod.qTrib.length > 0) {
                            detFormatados.qTrib = {
                              descricao: 'Quantidade Tributável',
                              value: Number(prod.qTrib[0]),
                              valorFormatado: prod.qTrib[0],
                            };
                          }
                        }

                        // vUnTrib
                        if (prod.vUnTrib) {
                          if (prod.vUnTrib.length > 0) {
                            detFormatados.vUnTrib = {
                              descricao: 'Valor Unitário de tributação',
                              value: Number(prod.vUnTrib[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vUnTrib[0]),
                              ),
                            };
                          }
                        }

                        // vFrete
                        if (prod.vFrete) {
                          if (prod.vFrete.length > 0) {
                            dadosFormatados.totalRepasseFreteCalculado += Number(
                              prod.vFrete[0],
                            );
                            detFormatados.vFrete = {
                              descricao: 'Valor Total do Frete',
                              value: Number(prod.vFrete[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vFrete[0]),
                              ),
                            };
                          }
                        }

                        // vSeg
                        if (prod.vSeg) {
                          if (prod.vSeg.length > 0) {
                            dadosFormatados.totalRepasseSeguroCalculado += Number(
                              prod.vSeg[0],
                            );
                            detFormatados.vSeg = {
                              descricao: 'Valor Total do Seguro',
                              value: Number(prod.vSeg[0]),
                              valorFormatado: formatMoeda(Number(prod.vSeg[0])),
                            };
                          }
                        }

                        // vDesc
                        if (prod.vDesc) {
                          if (prod.vDesc.length > 0) {
                            detFormatados.vDesc = {
                              descricao: 'Valor do Desconto',
                              value: Number(prod.vDesc[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vDesc[0]),
                              ),
                            };
                          }
                        }

                        // vOutro
                        if (prod.vOutro) {
                          if (prod.vOutro.length > 0) {
                            dadosFormatados.totalRepasseOutrasDespesasCalculado += Number(
                              prod.vOutro[0],
                            );
                            detFormatados.vOutro = {
                              descricao: 'Outras despesas acessórias',
                              value: Number(prod.vOutro[0]),
                              valorFormatado: formatMoeda(
                                Number(prod.vOutro[0]),
                              ),
                            };
                          }
                        }

                        // indTot
                        if (prod.indTot) {
                          if (prod.indTot.length > 0) {
                            detFormatados.indTot = {
                              descricao:
                                'Indica se valor do Item (vProd) entra no valor total da NF-e (vProd)',
                              value: prod.indTot[0],
                              valorFormatado: prod.indTot[0],
                            };
                          }
                        }

                        // rastro
                        if (prod.rastro) {
                          let dataVal = null;
                          for (let i = 0; i < prod.rastro.length; i++) {
                            const rastro = prod.rastro[i];

                            // dVal
                            if (rastro.dVal) {
                              if (rastro.dVal.length > 0) {
                                const dVal = new Date(rastro.dVal[0]);
                                if (!dataVal) {
                                  dataVal = dVal;
                                } else if (dataVal.getTime() < dVal.getTime()) {
                                  dataVal = dVal;
                                }
                              }
                            }
                          }

                          detFormatados.dVal = {
                            descricao: 'Data de validade',
                            value: dataVal,
                            valorFormatado: formatDateDDMMYYYY(dataVal),
                          };
                        }
                      }
                    }

                    if (det.imposto) {
                      if (det.imposto.length > 0) {
                        const imposto = det.imposto[0];
                        if (imposto.IPI) {
                          if (imposto.IPI.length > 0) {
                            const IPI = imposto.IPI[0];

                            // IPITrib
                            if (IPI.IPITrib) {
                              if (IPI.IPITrib.length > 0) {
                                const IPITrib = IPI.IPITrib[0];

                                // vIPI
                                if (IPITrib.vIPI) {
                                  if (IPITrib.vIPI.length > 0) {
                                    dadosFormatados.totalRepasseIpiCalculado += Number(
                                      IPITrib.vIPI[0],
                                    );
                                    detFormatados.vIPI = {
                                      descricao: 'Valor do IPI',
                                      value: Number(IPITrib.vIPI[0]),
                                      valorFormatado: formatMoeda(
                                        Number(IPITrib.vIPI[0]),
                                      ),
                                    };
                                  }
                                }
                              }
                            }
                          }
                        }

                        //////////////////////

                        if (imposto.ICMS) {
                          if (imposto.ICMS.length > 0) {
                            const ICMS = imposto.ICMS[0];

                            if (ICMS.ICMS10) {
                              if (ICMS.ICMS10.length > 0) {
                                const ICMS10 = ICMS.ICMS10[0];

                                // vICMSST
                                if (ICMS10.vICMSST) {
                                  if (ICMS10.vICMSST.length > 0) {
                                    dadosFormatados.totalRepasseSTCalculado += Number(
                                      ICMS10.vICMSST[0],
                                    );
                                    detFormatados.vICMSST = {
                                      descricao: 'Valor do ICMS ST',
                                      value: Number(ICMS10.vICMSST[0]),
                                      valorFormatado: formatMoeda(
                                        Number(ICMS10.vICMSST[0]),
                                      ),
                                    };
                                  }
                                }
                              }
                            }

                            if (ICMS.ICMS70) {
                              if (ICMS.ICMS70.length > 0) {
                                const ICMS70 = ICMS.ICMS70[0];

                                // vICMSST
                                if (ICMS70.vICMSST) {
                                  if (ICMS70.vICMSST.length > 0) {
                                    dadosFormatados.totalRepasseSTCalculado += Number(
                                      ICMS70.vICMSST[0],
                                    );
                                    detFormatados.vICMSST = {
                                      descricao: 'Valor do ICMS ST',
                                      value: Number(ICMS70.vICMSST[0]),
                                      valorFormatado: formatMoeda(
                                        Number(ICMS70.vICMSST[0]),
                                      ),
                                    };
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    dadosFormatados.itens.push(detFormatados);
                  }
                }

                /////////

                if (infNFe.total) {
                  if (infNFe.total.length > 0) {
                    const total = infNFe.total[0];

                    // ICMSTot
                    if (total.ICMSTot) {
                      if (total.ICMSTot.length > 0) {
                        const ICMSTot = total.ICMSTot[0];

                        // vST
                        if (ICMSTot.vST) {
                          if (ICMSTot.vST.length > 0) {
                            dadosFormatados.vST = {
                              descricao: 'Valor Total do ICMS ST  ',
                              value: Number(ICMSTot.vST[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vST[0]),
                              ),
                            };
                          }
                        }

                        // vProd
                        if (ICMSTot.vProd) {
                          if (ICMSTot.vProd.length > 0) {
                            dadosFormatados.vProd = {
                              descricao: 'Valor Total dos produtos e serviços',
                              value: Number(ICMSTot.vProd[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vProd[0]),
                              ),
                            };
                          }
                        }

                        // vFrete
                        if (ICMSTot.vFrete) {
                          if (ICMSTot.vFrete.length > 0) {
                            dadosFormatados.vFrete = {
                              descricao: 'Valor Total do Frete',
                              value: Number(ICMSTot.vFrete[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vFrete[0]),
                              ),
                            };
                          }
                        }

                        // vSeg
                        if (ICMSTot.vSeg) {
                          if (ICMSTot.vSeg.length > 0) {
                            dadosFormatados.vSeg = {
                              descricao: 'Valor Total do Seguro',
                              value: Number(ICMSTot.vSeg[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vSeg[0]),
                              ),
                            };
                          }
                        }

                        // vDesc
                        if (ICMSTot.vDesc) {
                          if (ICMSTot.vDesc.length > 0) {
                            dadosFormatados.vDesc = {
                              descricao: 'Valor Total do Desconto',
                              value: Number(ICMSTot.vDesc[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vDesc[0]),
                              ),
                            };
                          }
                        }

                        // vII
                        if (ICMSTot.vII) {
                          if (ICMSTot.vII.length > 0) {
                            dadosFormatados.vII = {
                              descricao: 'Valor Total do II',
                              value: Number(ICMSTot.vII[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vII[0]),
                              ),
                            };
                          }
                        }

                        // vIPI
                        if (ICMSTot.vIPI) {
                          if (ICMSTot.vIPI.length > 0) {
                            dadosFormatados.vIPI = {
                              descricao: 'Valor Total do IPI',
                              value: Number(ICMSTot.vIPI[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vIPI[0]),
                              ),
                            };
                          }
                        }

                        // vIPIDevol
                        if (ICMSTot.vIPIDevol) {
                          if (ICMSTot.vIPIDevol.length > 0) {
                            dadosFormatados.vIPIDevol = {
                              descricao: 'Valor Total do IPI devolvido',
                              value: Number(ICMSTot.vIPIDevol[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vIPIDevol[0]),
                              ),
                            };
                          }
                        }

                        // vOutro
                        if (ICMSTot.vOutro) {
                          if (ICMSTot.vOutro.length > 0) {
                            dadosFormatados.vOutro = {
                              descricao: 'Outras Despesas acessórias',
                              value: Number(ICMSTot.vOutro[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vOutro[0]),
                              ),
                            };
                          }
                        }

                        // vNF
                        if (ICMSTot.vNF) {
                          if (ICMSTot.vNF.length > 0) {
                            dadosFormatados.vNF = {
                              descricao: 'Valor Total da NF-e',
                              value: Number(ICMSTot.vNF[0]),
                              valorFormatado: formatMoeda(
                                Number(ICMSTot.vNF[0]),
                              ),
                            };
                          }
                        }
                      }
                    }
                  }
                }

                ////////////

                if (infNFe.cobr) {
                  if (infNFe.cobr.length > 0) {
                    const cobr = infNFe.cobr[0];

                    // fat
                    if (cobr.fat) {
                      if (cobr.fat.length > 0) {
                        const fat = cobr.fat[0];

                        // nFat
                        if (fat.nFat) {
                          if (fat.nFat.length > 0) {
                            dadosFormatados.nFat = {
                              descricao: 'Número da Fatura',
                              value: fat.nFat[0],
                              valorFormatado: fat.nFat[0],
                            };
                          }
                        }

                        // vOrig
                        if (fat.vOrig) {
                          if (fat.vOrig.length > 0) {
                            dadosFormatados.vOrig = {
                              descricao: 'Valor Original da Fatura',
                              value: Number(fat.vOrig[0]),
                              valorFormatado: formatMoeda(Number(fat.vOrig[0])),
                            };
                          }
                        }

                        // vDesc
                        if (fat.vDesc) {
                          if (fat.vDesc.length > 0) {
                            dadosFormatados.vDesc = {
                              descricao: 'Valor do desconto',
                              value: Number(fat.vDesc[0]),
                              valorFormatado: formatMoeda(Number(fat.vDesc[0])),
                            };
                          }
                        }

                        // vLiq
                        if (fat.vLiq) {
                          if (fat.vLiq.length > 0) {
                            dadosFormatados.vLiq = {
                              descricao: 'Valor Líquido da Fatura',
                              value: Number(fat.vLiq[0]),
                              valorFormatado: formatMoeda(Number(fat.vLiq[0])),
                            };
                          }
                        }
                      }
                    }

                    // dup
                    if (cobr.dup) {
                      const dups = cobr.dup;

                      for (let i = 0; i < dups.length; i++) {
                        const dup = dups[i];
                        const dupFormatado = [];
                        // nDup
                        if (dup.nDup) {
                          if (dup.nDup.length > 0) {
                            dupFormatado.nDup = {
                              descricao: 'Número da Parcela',
                              value: dup.nDup[0],
                              valorFormatado: dup.nDup[0],
                            };
                          }
                        }

                        // dVenc
                        if (dup.dVenc) {
                          if (dup.dVenc.length > 0) {
                            dupFormatado.dVenc = {
                              descricao: 'Data de vencimento',
                              value: new Date(dup.dVenc[0]),
                              valorFormatado: formatDateDDMMYYYY(
                                new Date(dup.dVenc[0]),
                              ),
                            };
                          }
                        }

                        // vDup
                        if (dup.vDup) {
                          if (dup.vDup.length > 0) {
                            dupFormatado.vDup = {
                              descricao: 'Valor da Parcela',
                              value: Number(dup.vDup[0]),
                              valorFormatado: formatMoeda(Number(dup.vDup[0])),
                            };
                          }
                        }

                        dadosFormatados.parcelas.push(dupFormatado);
                      }
                    }
                  }
                }

                /////////

                if (infNFe.pag) {
                  if (infNFe.pag.length > 0) {
                    const pag = infNFe.pag[0];

                    // detPag
                    if (pag.detPag) {
                      for (let i = 0; i < pag.detPag.length; i++) {
                        const detPag = pag.detPag[i];
                        const detPagFormatado = {};

                        // indPag
                        if (detPag.indPag) {
                          if (detPag.indPag.length > 0) {
                            detPagFormatado.indPag = {
                              descricao: 'Indicador da Forma de Pagamento',
                              value: detPag.indPag[0],
                              valorFormatado:
                                codigosIndicadorFormaDePagamento[
                                  detPag.indPag[0]
                                ],
                            };
                          }
                        }

                        // tPag
                        if (detPag.tPag) {
                          if (detPag.tPag.length > 0) {
                            detPagFormatado.tPag = {
                              descricao: 'Meio de pagamento',
                              value: detPag.tPag[0],
                              valorFormatado:
                                codigosMeiosDePagamento[detPag.tPag[0]],
                            };
                          }
                        }

                        // vPag
                        if (detPag.vPag) {
                          if (detPag.vPag.length > 0) {
                            detPagFormatado.vPag = {
                              descricao: 'Valor do Pagamento',
                              value: Number(detPag.vPag[0]),
                              valorFormatado: formatMoeda(
                                Number(detPag.vPag[0]),
                              ),
                            };
                          }
                        }

                        dadosFormatados.pagamentos.push(detPagFormatado);
                      }
                    }
                  }
                }

                /////////////
              }
            }
          }
        }
      }
    }
    return dadosFormatados;
  }

  async function buscarXml() {
    if (!analisando.current) {
      analisando.current = true;
      setCarregando(true);

      try {
        const response = await api.get(`/get_xml_nfe_compra/${chaveDeAcesso}`);
        const data = response.data;
        if (!data.status) throw data.message;
        const result = await xml2js.parseStringPromise(data.data, {
          mergeAttrs: true,
        });
        const dados = getDadosUteisXml(result);
        handleBuscarAssociacoes(dados);
      } catch (e) {
        analisando.current = false;
        setCarregando(false);
        setDadosXML(null);
        setDadosServidor(null);
        cancelarCompra();
        enqueueSnackbar(e ? e.toString() : '', {
          variant: 'error',
        });
      }
    }
  }

  useEffect(() => {
    if (chaveDeAcesso) {
      buscarXml();
    }
  }, [chaveDeAcesso]);

  /*enqueueSnackbar('É necessário ao menos um lote na compra!', {
    variant: 'warning',
  });*/

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleBuscarAssociacoes(dadosXML) {
    try {
      if (dadosXML) {
        const fornecedorBody = dadosXML.remetente.CNPJ
          ? dadosXML.remetente.CNPJ.value
          : '';

        const fornecedorBody2 = dadosXML.remetente.CPF
          ? dadosXML.remetente.CPF.value
          : '';

        const produtosBody = dadosXML.itens
          .map((item) => ({
            codigo: item.cEANTrib ? item.cEANTrib.value : '',
          }))
          .filter((item) => item.codigo !== '' && item.codigo !== 'SEM GTIN');

        const dadosDescricoes = dadosXML.itens
          .map((item) => {
            //const cEANTrib = item.cEANTrib ? item.cEANTrib.value : '';
            //if (cEANTrib === '') {
            return {
              xProd: item.xProd
                ? item.xProd.value.trim().replace(/\s{2,}/g, ' ')
                : '',
            };
            //}

            return '';
          })
          .filter((item) => item.xProd !== '');

        const response = await api.post('/identifica-dados-xml-compra', {
          dadosProdutos: produtosBody,
          dadosDescricoes,
          dadosFornecedor: {
            cnpj: fornecedorBody,
            cpf: fornecedorBody2,
          },
        });

        const destinatarioCnpj = dadosXML.destinatario.CNPJ
          ? dadosXML.destinatario.CNPJ.value
          : '';

        const data = response.data;

        const { fornecedor: fornecedor2, produtos, cnpj } = data;

        for (let i = 0; i < produtos.length; i++) {
          const {
            precoOfertaJornal,
            precoOfertaZerarEstoque,
          } = getPrecosOfertas(produtos[i]);
          produtos[i].precoOfertaJornal = precoOfertaJornal;
          produtos[i].precoOfertaZerarEstoque = precoOfertaZerarEstoque;
        }

        if (destinatarioCnpj !== cnpj)
          throw 'O destinatário desta nota não é a Caruaru frios';

        analisando.current = false;

        setCarregando(false);

        setDadosServidor({
          fornecedor: fornecedor2,
          produtos,
        });

        setDadosXML(dadosXML);
      } else {
        analisando.current = false;
        setCarregando(false);
        setDadosXML(null);
        setDadosServidor(null);
        enqueueSnackbar('Nenhum dado para analisar', {
          variant: 'error',
        });
      }
    } catch (e) {
      analisando.current = false;
      setCarregando(false);
      setDadosXML(null);
      setDadosServidor(null);
      enqueueSnackbar(e ? e.toString() : '', {
        variant: 'error',
      });
    }
  }

  function getMapProdutos() {
    const prods = {};
    if (dadosServidor) {
      for (let i = 0; i < dadosServidor.produtos.length; i++) {
        const produto = dadosServidor.produtos[i];
        if (produto.codigo) {
          prods[produto.codigo] = produto;
        }

        if (produto.codigo_caixa_nivel_1) {
          prods[produto.codigo_caixa_nivel_1] = produto;
        }

        if (produto.codigo_caixa_nivel_2) {
          prods[produto.codigo_caixa_nivel_2] = produto;
        }

        if (produto.codigo_caixa_nivel_3) {
          prods[produto.codigo_caixa_nivel_3] = produto;
        }

        if (produto.descricao_pesquisa_xml) {
          prods[produto.descricao_pesquisa_xml] = produto;
        }
      }
    }
    return prods;
  }

  const mapaProdutos = getMapProdutos();

  function getRepasses() {
    if (dadosXML) {
      return {
        totalRepasseFreteCalculado: round10(
          dadosXML.totalRepasseFreteCalculado,
          -2,
        ),
        totalRepasseSeguroCalculado: round10(
          dadosXML.totalRepasseSeguroCalculado,
          -2,
        ),
        totalRepasseOutrasDespesasCalculado: round10(
          dadosXML.totalRepasseOutrasDespesasCalculado,
          -2,
        ),
        totalRepasseIpiCalculado: round10(
          dadosXML.totalRepasseIpiCalculado,
          -2,
        ),
        totalRepasseSTCalculado: round10(dadosXML.totalRepasseSTCalculado, -2),
        ////
        totalRepasseFreteXML: dadosXML.vFrete
          ? round10(dadosXML.vFrete.value, -2)
          : 0,
        totalRepasseSeguroXML: dadosXML.vSeg
          ? round10(dadosXML.vSeg.value, -2)
          : 0,
        totalRepasseOutrasDespesasXML: dadosXML.vOutro
          ? round10(dadosXML.vOutro.value, -2)
          : 0,
        totalRepasseIpiXML: dadosXML.vIPI
          ? round10(dadosXML.vIPI.value, -2)
          : 0,
        totalRepasseSTXML: dadosXML.vST ? round10(dadosXML.vST.value, -2) : 0,
      };
    }

    return {
      totalRepasseFreteCalculado: 0,
      totalRepasseSeguroCalculado: 0,
      totalRepasseOutrasDespesasCalculado: 0,
      totalRepasseIpiCalculado: 0,
      totalRepasseSTCalculado: 0,
      ////
      totalRepasseFreteXML: 0,
      totalRepasseSeguroXML: 0,
      totalRepasseOutrasDespesasXML: 0,
      totalRepasseIpiXML: 0,
      totalRepasseSTXML: 0,
    };
  }

  const {
    totalRepasseFreteCalculado,
    totalRepasseFreteXML,
    totalRepasseIpiCalculado,
    totalRepasseIpiXML,
    totalRepasseOutrasDespesasCalculado,
    totalRepasseOutrasDespesasXML,
    totalRepasseSTCalculado,
    totalRepasseSTXML,
    totalRepasseSeguroCalculado,
    totalRepasseSeguroXML,
  } = getRepasses();

  let nErrorsRepasses = 0;

  let hasErrorRepasseFrete = false;

  if (totalRepasseFreteCalculado !== totalRepasseFreteXML) {
    if (totalRepasseFreteCalculado !== 0) {
      hasErrorRepasseFrete = true;
      nErrorsRepasses++;
    }
  }

  let hasErrorRepasseIpi = false;

  if (totalRepasseIpiCalculado !== totalRepasseIpiXML) {
    if (totalRepasseIpiCalculado !== 0) {
      hasErrorRepasseIpi = true;
      nErrorsRepasses++;
    }
  }

  let hasErrorRepasseOutrasDespesas = false;

  if (totalRepasseOutrasDespesasCalculado !== totalRepasseOutrasDespesasXML) {
    if (totalRepasseOutrasDespesasCalculado !== 0) {
      hasErrorRepasseOutrasDespesas = true;
      nErrorsRepasses++;
    }
  }

  let hasErrorRepasseST = false;

  if (totalRepasseSTCalculado !== totalRepasseSTXML) {
    if (totalRepasseSTCalculado !== 0) {
      hasErrorRepasseST = true;
      nErrorsRepasses++;
    }
  }

  let hasErrorRepasseSeguro = false;

  if (totalRepasseSeguroCalculado !== totalRepasseSeguroXML) {
    if (totalRepasseSeguroCalculado !== 0) {
      hasErrorRepasseSeguro = true;
      nErrorsRepasses++;
    }
  }

  let errosLotes = 0;
  let errosZerarEstoque = 0;

  const itensFormatados = dadosXML
    ? dadosXML.itens.map((item) => {
        let correspondencia =
          mapaProdutos[item.cEANTrib ? item.cEANTrib.value : ''];
        if (!correspondencia) {
          correspondencia =
            mapaProdutos[
              item.xProd ? item.xProd.value.trim().replace(/\s{2,}/g, ' ') : ''
            ];
          if (correspondencia) {
            if (correspondencia.precoOfertaZerarEstoque) {
              errosZerarEstoque++;
            }
          }
        } else if (correspondencia.precoOfertaZerarEstoque) {
          errosZerarEstoque++;
        }
        if (!correspondencia) errosLotes++;
        return {
          ...item,
          correspondencia,
        };
      })
    : [];

  if (errosLotes === 0 && itensFormatados.length === 0) {
    errosLotes = 1;
  }

  const errorFornecedor = dadosServidor
    ? dadosServidor.fornecedor
      ? false
      : true
    : true;

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={
          analisando.current ||
          errorFornecedor ||
          errosZerarEstoque > 0 ||
          errosLotes > 0 ||
          itensFormatados.length === 0 ||
          nErrorsRepasses > 0
        }
        onClick={handleActions}
        FECHAR_COMPRA_ACTION={FECHAR_COMPRA_ACTION}
        CANCELAR_COMPRA_ACTION={CANCELAR_COMPRA_ACTION}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0px 0px',
          }}
        >
          <Avatar className={classes.recarregar} onClick={() => buscarXml()}>
            {!carregando ? (
              <RefreshIcon style={{ fontSize: 30 }} />
            ) : (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Avatar>
        </div>
        {dadosXML ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              width: 'min-content',
              margin: '10px auto',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Badge color="error" badgeContent={nErrorsRepasses}>
                    <Typography>Geral</Typography>
                  </Badge>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Badge color="error" badgeContent={errorFornecedor ? 1 : 0}>
                    <Typography>Fornecedor</Typography>
                  </Badge>
                }
                {...a11yProps(1)}
              />
              <Tab label="Destinatário" {...a11yProps(2)} />
              <Tab
                label={
                  <Badge color="error" badgeContent={errosLotes}>
                    <Typography>Lotes</Typography>
                  </Badge>
                }
                {...a11yProps(3)}
              />
              <Tab label="Parcelas" {...a11yProps(4)} />
            </Tabs>
          </div>
        ) : null}
        <TabPanel value={value} index={0}>
          {dadosXML ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div>
                {hasErrorRepasseFrete ? (
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                      color: 'yellow',
                    }}
                  >
                    {`Total do Repasse do Frete Calculado (${formatMoeda(
                      totalRepasseFreteCalculado,
                    )}) pela soma dos produtos é
                    diferente do Repasse do Frete total da nota (${formatMoeda(
                      totalRepasseFreteXML,
                    )})`}
                  </div>
                ) : null}
                {hasErrorRepasseIpi ? (
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                      color: 'yellow',
                    }}
                  >
                    {`Total do Repasse do IPI Calculado pela soma dos produtos (${formatMoeda(
                      totalRepasseIpiCalculado,
                    )}) é
                    diferente do Repasse do IPI total da nota (${formatMoeda(
                      totalRepasseIpiXML,
                    )})`}
                  </div>
                ) : null}
                {hasErrorRepasseOutrasDespesas ? (
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                      color: 'yellow',
                    }}
                  >
                    {`Total do Repasse de Outras Despesas Calculado pela soma dos
                    produtos (${formatMoeda(
                      totalRepasseOutrasDespesasCalculado,
                    )}) ${totalRepasseOutrasDespesasCalculado} é diferente do Repasse de Outras Despesas total da
                    nota (${formatMoeda(
                      totalRepasseOutrasDespesasXML,
                    )}) ${totalRepasseOutrasDespesasXML}`}
                  </div>
                ) : null}
                {hasErrorRepasseST ? (
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                      color: 'yellow',
                    }}
                  >
                    {`Total do Repasse ST Calculado pela soma dos produtos (${formatMoeda(
                      totalRepasseSTCalculado,
                    )}) é
                    diferente do Repasse ST total da nota (${formatMoeda(
                      totalRepasseSTXML,
                    )})`}
                  </div>
                ) : null}
                {hasErrorRepasseSeguro ? (
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                      color: 'yellow',
                    }}
                  >
                    {`Total do Repasse de Seguro Calculado pela soma dos produtos (${formatMoeda(
                      totalRepasseSeguroCalculado,
                    )}) é
                    diferente do Repasse de Seguro total da nota (${formatMoeda(
                      totalRepasseSeguroXML,
                    )})`}
                  </div>
                ) : null}
              </div>
              <Typography
                variant="h4"
                gutterBottom
                style={{
                  //margin: '20px 0 10px',
                  textAlign: 'center',
                  color: 'red',
                }}
              >
                Dados da Nota
              </Typography>
              <Table
                aria-label="customized table"
                style={{
                  width: '50%',
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      Campo
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      Valor
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosXML.Id ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.Id.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.Id.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.serie ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.serie.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.serie.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.nNF ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.nNF.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.nNF.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.dhEmi ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.dhEmi.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.dhEmi.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vST ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vST.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vST.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vIPI ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vIPI.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vIPI.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}

                  {dadosXML.vFrete ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vFrete.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vFrete.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vSeg ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vSeg.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vSeg.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}

                  {dadosXML.vOutro ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vOutro.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vOutro.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vDesc ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vDesc.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vDesc.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vProd ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vProd.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vProd.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vNF ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vNF.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vNF.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </div>
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {dadosXML ? (
            dadosXML.remetente ? (
              <div>
                {dadosServidor ? (
                  dadosServidor.fornecedor ? null : (
                    <div
                      style={{
                        fontSize: '15px',
                        textAlign: 'center',
                        color: 'yellow',
                      }}
                    >
                      Fornecedor não encontrado no sistema
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      fontSize: '15px',
                      textAlign: 'center',
                      color: 'yellow',
                    }}
                  >
                    Fornecedor não encontrado no sistema
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                      margin: '20px 0 10px',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      {dadosServidor ? (
                        dadosServidor.fornecedor ? (
                          <Avatar className={classes.confirmado}>
                            <CheckIcon style={{ fontSize: 15 }} />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.pendente}>
                            <PriorityHighIcon style={{ fontSize: 15 }} />
                          </Avatar>
                        )
                      ) : (
                        <Avatar className={classes.pendente}>
                          <PriorityHighIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      )}
                    </div>
                    <Typography
                      variant="h4"
                      style={{
                        textAlign: 'center',
                        color: 'red',
                        //marginBottom: '10px',
                      }}
                    >
                      Fornecedor
                    </Typography>
                  </div>

                  <Table
                    aria-label="customized table"
                    style={{
                      width: '50%',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Campo
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dadosXML.remetente.CNPJ ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.CNPJ.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.CNPJ.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.CPF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.CPF.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.CPF.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.xNome ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xNome.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xNome.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.xFant ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xFant.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xFant.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.IE ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.IE.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.IE.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}

                      {dadosXML.remetente.xLgr ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xLgr.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xLgr.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.nro ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.nro.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.nro.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.nro ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.nro.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.nro.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.xCpl ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xCpl.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xCpl.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.xBairro ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xBairro.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xBairro.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.xMun ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xMun.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.xMun.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.UF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.UF.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.UF.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.CEP ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.CEP.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.CEP.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.remetente.fone ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.fone.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.remetente.fone.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              </div>
            ) : null
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {dadosXML ? (
            dadosXML.destinatario ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: 'center',
                      color: 'red',
                      marginBottom: '10px',
                    }}
                  >
                    Destinatário
                  </Typography>

                  <Table
                    aria-label="customized table"
                    style={{
                      width: '50%',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Campo
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dadosXML.destinatario.CNPJ ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.CNPJ.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.CNPJ.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.CPF ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.CPF.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.CPF.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.xNome ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xNome.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xNome.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.xFant ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xFant.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xFant.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.IE ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.IE.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.IE.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.email ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.email.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.email.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.xLgr ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xLgr.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xLgr.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.nro ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.nro.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.nro.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.xCpl ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xCpl.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xCpl.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.xBairro ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xBairro.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xBairro.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.xMun ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xMun.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.xMun.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.CEP ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.CEP.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.CEP.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                      {dadosXML.destinatario.fone ? (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.fone.descricao}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {dadosXML.destinatario.fone.valorFormatado}
                          </StyledTableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              </div>
            ) : null
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {dadosXML ? (
            dadosXML.itens ? (
              dadosXML.itens.length > 0 ? (
                <div>
                  {itensFormatados === 0 ? (
                    <div
                      style={{
                        fontSize: '15px',
                        textAlign: 'center',
                        color: 'yellow',
                      }}
                    >
                      Esta nota não possui nenhum lote para importar
                    </div>
                  ) : (
                    <>
                      {errosLotes > 0 ? (
                        <div
                          style={{
                            fontSize: '15px',
                            textAlign: 'center',
                            color: 'yellow',
                          }}
                        >{`${errosLotes} itens não foram encontradas correspondências de produtos no sistema`}</div>
                      ) : null}
                      {errosZerarEstoque > 0 ? (
                        <div
                          style={{
                            fontSize: '15px',
                            textAlign: 'center',
                            color: 'yellow',
                          }}
                        >{`${errosZerarEstoque} itens Possuem Ofertas de Zerar Estoque Ativas na Loja`}</div>
                      ) : null}
                    </>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: 'center', color: 'red' }}
                    >
                      Produtos
                    </Typography>
                    <div
                      style={{
                        width: '75%',
                        overflowX: 'scroll',
                      }}
                    >
                      <Table aria-label="customized table" style={{}}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Ações
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Código GTIN
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Descrição do produto no SISTEMA
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Descrição do produto ou serviço
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Código NCM com 8 dígitos
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Unidade Comercial
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Quantidade Comercial
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Valor Unitário de Comercialização
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Valor Total Bruto dos Produtos ou Serviços
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              GTIN da unidade tributável
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Unidade Tributável
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Quantidade Tributável
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Valor Unitário de tributação
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Valor Total do Frete
                            </StyledTableCell>

                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Valor Total do Seguro
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Valor do Desconto
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Outras despesas acessórias
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              IPI
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              ICMS ST
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: 'white',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              Validade
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {itensFormatados.map((item) => (
                            <TableRow>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.correspondencia ? (
                                  <div style={{ display: 'flex', gap: '10px' }}>
                                    {!item.correspondencia
                                      .precoOfertaZerarEstoque ? (
                                      <Avatar className={classes.confirmado}>
                                        <CheckIcon style={{ fontSize: 15 }} />
                                      </Avatar>
                                    ) : (
                                      <div
                                        style={{ display: 'flex', gap: '10px' }}
                                      >
                                        <Avatar className={classes.pendente}>
                                          <PriorityHighIcon
                                            style={{ fontSize: 15 }}
                                          />
                                        </Avatar>
                                        TEM OFERTA DE ZERAR ESTOQUE
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div style={{ display: 'flex', gap: '10px' }}>
                                    <Avatar className={classes.pendente}>
                                      <PriorityHighIcon
                                        style={{ fontSize: 15 }}
                                      />
                                    </Avatar>
                                    ITEM NÃO ENCONTRADO
                                  </div>
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.cEAN ? item.cEAN.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.xProd ? item.xProd.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.correspondencia
                                  ? item.correspondencia.nome
                                  : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.NCM ? item.NCM.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.uCom ? item.uCom.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.qCom ? item.qCom.valorFormatado : ''}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vUnCom ? item.vUnCom.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vProd ? item.vProd.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.cEANTrib
                                  ? item.cEANTrib.valorFormatado
                                  : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.uTrib ? item.uTrib.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.qTrib ? item.qTrib.valorFormatado : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vUnTrib
                                  ? item.vUnTrib.valorFormatado
                                  : '-'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vFrete
                                  ? item.vFrete.valorFormatado
                                  : 'R$: 0,00'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vSeg
                                  ? item.vSeg.valorFormatado
                                  : 'R$: 0,00'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vDesc
                                  ? item.vDesc.valorFormatado
                                  : 'R$: 0,00'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vOutro
                                  ? item.vOutro.valorFormatado
                                  : 'R$: 0,00'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vIPI
                                  ? item.vIPI.valorFormatado
                                  : 'R$: 0,00'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.vICMSST
                                  ? item.vICMSST.valorFormatado
                                  : 'R$: 0,00'}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  backgroundColor: 'white',
                                }}
                              >
                                {item.dVal ? item.dVal.valorFormatado : '-'}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {dadosXML ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                style={{ textAlign: 'center', color: 'red' }}
              >
                Cobrança
              </Typography>
              <Table
                aria-label="customized table"
                style={{
                  width: '50%',
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      Campo
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      Valor
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosXML.nFat ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.nFat.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.nFat.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vOrig ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vOrig.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vOrig.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vDesc ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vDesc.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vDesc.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                  {dadosXML.vLiq ? (
                    <TableRow>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vLiq.descricao}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: 'white',
                        }}
                      >
                        {dadosXML.vLiq.valorFormatado}
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </div>
          ) : null}
          {dadosXML ? (
            dadosXML.parcelas ? (
              dadosXML.parcelas.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: 'center', color: 'red' }}
                  >
                    Parcelas
                  </Typography>
                  <Table
                    aria-label="customized table"
                    style={{
                      width: '50%',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Número da Parcela
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Data de vencimento
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor da Parcela
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dadosXML.parcelas.map((item) => (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.nDup ? item.nDup.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.dVenc ? item.dVenc.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vDup ? item.vDup.valorFormatado : ''}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : null
            ) : null
          ) : null}

          {dadosXML ? (
            dadosXML.pagamentos ? (
              dadosXML.pagamentos.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: 'center', color: 'red' }}
                  >
                    Pagamentos
                  </Typography>
                  <Table
                    aria-label="customized table"
                    style={{
                      width: '50%',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Indicador da Forma de Pagamento
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Meio de pagamento
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          Valor do Pagamento
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dadosXML.pagamentos.map((item) => (
                        <TableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.indPag ? item.indPag.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.tPag ? item.tPag.valorFormatado : ''}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            {item.vPag ? item.vPag.valorFormatado : ''}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : null
            ) : null
          ) : null}
        </TabPanel>
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoPassadorLotes
        ref={refDialogoPassadorLotes}
        handleClose={() => {}}
        handleSalvar={(lotes, parcelas) => handleConcluido(lotes, parcelas)}
      />
    </>
  );
}

export default TipoCompra;
