import React, { useEffect, useState, useRef } from 'react';
import {
  MenuItemLink,
  getResources,
  setSidebarVisibility,
  useRefresh,
} from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';

import { useMediaQuery } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BuildIcon from '@material-ui/icons/Build';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PagesIcon from '@material-ui/icons/Pages';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import {
  DialogoTransacao,
  DialogoDespesaCartaoCredito,
} from '../../Components';
import { getListAllAPI, api, getUserId, getOneAPI } from '../../services';
import SubMenu from './SubMenu';

const useStyles = makeStyles((theme) => ({
  itemLogo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '15px',
    padding: '10px',
    paddingTop: 'px',
    marginBottom: '10px',
  },
  logo: {
    display: 'block',
    width: 'auto',
    height: '50px',
    backgroundColor: theme.palette.primary.main,
    padding: '5px',
    borderRadius: '5px',
  },
  content: {
    marginLeft: '5px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  activeIcon: {
    color: 'blue',
  },
  transacao: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#0039cb',
    backgroundColor: '#0039cb',
  },
  despesa: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#f44336',
    backgroundColor: '#f44336',
  },
  receita: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: '#4caf50',
    backgroundColor: '#4caf50',
  },
}));

const StyledMenuItemLink = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {},
    marginLeft: '7px',
  },
  active: {
    borderLeft: '5px solid red',
    borderColor: '#00897B',
    backgroundColor: 'rgba(0, 138, 124, 0.15)',
    marginLeft: '2px',
  },
}))(MenuItemLink);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '20px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const TipoTransacaoSelect = ({ open, handleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (code) => {
    handleClose();
    handleChange(code);
  };

  function getDados() {
    switch ('Transacao') {
      case 'Transacao':
        return {
          color: '#0039cb',
          label: 'Transação',
        };
      case 'Despesa':
        return {
          color: '#f44336',
          label: 'Despesa',
        };
      case 'Receita':
        return {
          color: '#4caf50',
          label: 'Receita',
        };
      case 'Transferência':
        return {
          color: '#0039cb',
          label: 'Transferência',
        };
      default:
        return {
          color: '',
          label: '',
        };
    }
  }

  const dados = getDados();

  return (
    <>
      <Fab
        variant={open ? 'extended' : 'circular'}
        style={{
          backgroundColor: 'white',
          color: dados.color,
          fontWeight: 'bold',
        }}
        onClick={(e) => {
          handleClick(e);
        }}
        size={open ? 'medium' : 'small'}
      >
        <AddIcon
          style={{
            color: dados.color,
            marginRight: open ? '5px' : '0px',
          }}
        />
        {open && <>{`Nova ${dados.label}`}</>}
      </Fab>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
        transitionDuration={500}
      >
        <StyledMenuItem onClick={() => handleClickItem('Despesa')}>
          <ListItemIcon>
            <TrendingDownIcon style={{ color: '#f44336' }} />
          </ListItemIcon>
          <ListItemText primary="Despesas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Receita')}>
          <ListItemIcon>
            <TrendingUpIcon style={{ color: '#4caf50' }} />
          </ListItemIcon>
          <ListItemText primary="Receitas" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Transferência')}>
          <ListItemIcon>
            <AutorenewIcon style={{ color: '#0039cb' }} />
          </ListItemIcon>
          <ListItemText primary="Transferências" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClickItem('Despesa_cartao')}>
          <ListItemIcon>
            <CreditCardIcon style={{ color: '#00796b' }} />
          </ListItemIcon>
          <ListItemText primary="Despesa cartão" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const MenuLocal = ({ onMenuClick, logout }) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const dispatch = useDispatch();
  const [open2, setOpen2] = useState(open);
  const refDialogoTransacao = useRef(null);
  const refDialogoDespesaCartao = useRef(null);
  const refresh = useRefresh();
  const [cargo, setCargo] = useState(null);
  const [logoApp, setLogoApp] = useState('');
  const [labelApp, setLabelApp] = useState('');
  const [colaborador, setColaborador] = useState(null);
  const [isGerenteCaixa, setIsGerenteCaixa] = useState(false);

  useEffect(() => {
    async function getData() {
      const userId = getUserId();
      const data = await getListAllAPI(
        'users',
        ['id', 'asc'],
        { id: [userId] },
        ['cargo', 'colaborador'],
      );
      if (data.data.length > 0) {
        setIsGerenteCaixa(data.data[0].is_responsavel);
        setCargo(data.data[0].cargo);
        setColaborador(data.data[0].colaborador);
      } else {
        setCargo(null);
        setColaborador(null);
      }

      const config = await getOneAPI('config', 1, []);

      setLabelApp(config.data.label_app_gerencial);
      setLogoApp(config.data.logo_app_gerencial);
    }
    getData();
  }, []);

  const [menuOpen, setMenuOpen] = useState({
    menuPessoal: false,
    menuComprador: false,
    menuVendas: false,
    menuCompras: false,
    menuConfig: false,
    menuContabilidade: false,
    menuRelatorio: false,
    menuProduto: false,
    menuDevelopment: false,
    menuConferencia: false,
    menuContabilidadeEmpregos: false,
    menuEquipamentos: false,
    menuTelevendas: false,
    menuGlobal: false,
    menuInsumo: false,
    menuMalote: false,
    menuColetor: false,
    menuProcessos: false,
    menuDesenvolvedor: false,
  });

  useEffect(() => {
    if (open !== open2) dispatch(setSidebarVisibility(open2));
  }, [open2]);

  useEffect(() => {
    if (open !== open2) setOpen2(open);
  }, [open]);

  const handleToggle = (menuNome) => {
    setMenuOpen((state) => ({ ...state, [menuNome]: !state[menuNome] }));
  };

  function hasGetElement(resourceName) {
    for (let i = 0; i < resources.length; i += 1) {
      if (resources[i].name === resourceName) return resources[i];
    }
    return null;
  }

  function hasResource(resourceName) {
    const resource = hasGetElement(resourceName);
    const visible = resource ? resource.hasList : false;
    return visible ? (
      <StyledMenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={resource.options.label}
        leftIcon={<resource.icon className={classes.icon} />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    ) : null;
  }

  const create = (tipo) => {
    if (tipo === 'Despesa_cartao') {
      if (refDialogoDespesaCartao.current) {
        refDialogoDespesaCartao.current.handleCreate();
      }
    } else if (refDialogoTransacao.current) {
      refDialogoTransacao.current.handleCreate(tipo);
    }
  };

  const handleCloseDialogoTransacao = () => {
    refresh();
  };

  const valorDefault = false;

  function getPermissoesDestrinchadas() {
    if (cargo) {
      const showPessoal = cargo.pessoal_turnos;
      const showComprador =
        cargo.comprador_configuracoes_produtos ||
        cargo.mais_opcoes_banco_fornecedores ||
        cargo.mais_opcoes_funcao_funcionario_fornecedor ||
        cargo.sistema_jornal_de_ofertas ||
        cargo.sistema_cotacoes_templates ||
        cargo.sistema_sugestao_pre_compra ||
        cargo.sistema_tela_representantes ||
        cargo.sistema_oferta_zerar_estoque;
      const showVendas =
        cargo.gerencial_vendas ||
        cargo.gerencial_devolucoes ||
        cargo.sistema_ratoeira ||
        cargo.sistema_credito_devolucao ||
        cargo.sistema_pedidos_liberacoes ||
        cargo.sistema_aprovacoes_pedidos_liberacoes ||
        cargo.gerencial_pedidos;
      const showCompras =
        cargo.gerencial_compras ||
        cargo.sistema_nfe_compra ||
        cargo.sistema_nfe_compra_problematicas ||
        cargo.gerencial_fornecedores ||
        cargo.fornecimento_fornecedores_insumos ||
        cargo.fornecimento_fornecedores_servicos ||
        cargo.fornecimento_fornecedores_qualquer;
      const showProduto =
        cargo.produtos_produtos ||
        cargo.produtos_familias ||
        //cargo.produtos_ofertas ||
        cargo.produtos_categorias ||
        cargo.produtos_taras ||
        cargo.produtos_baixar ||
        cargo.produtos_locais_setores ||
        cargo.produtos_tabela_lucro ||
        cargo.produtos_impostos ||
        cargo.conferencias_sugestao_produtos ||
        cargo.sistema_conferencia_caixaria ||
        cargo.sistema_conferencia_grandes_pesagens ||
        cargo.sistema_historico_precificacao ||
        cargo.sistema_verbas_produtos ||
        cargo.sistema_liberacao_de_precos ||
        cargo.sistema_tela_validade ||
        cargo.gerencial_setores;
      const showFinanceiro =
        cargo.financeiro_dashboard ||
        cargo.financeiro_sessoes ||
        cargo.financeiro_contas ||
        cargo.financeiro_cartoes_credito ||
        cargo.financeiro_transacoes ||
        cargo.financeiro_pix ||
        cargo.financeiro_nfes ||
        cargo.financeiro_tela_tranferencias ||
        cargo.financeiro_tela_reajustes ||
        cargo.mais_opcoes_categorias_transacoes ||
        cargo.financeiro_transacoes_simuladas ||
        cargo.financeiro_vendas_faturadas ||
        cargo.sistema_contas_tabela ||
        cargo.sistema_transacoes_conferencias ||
        cargo.sistema_despesas_agendamentos ||
        cargo.sistema_caixas_fc ||
        cargo.sistema_conferencia_maquineta ||
        cargo.sistema_transacoes_especiais ||
        cargo.financeiro_futurologia;
      const showRelatorio =
        cargo.relatorios_mensal ||
        cargo.relatorios_anual ||
        cargo.relatorios_produtos ||
        cargo.relatorios_clientes ||
        cargo.produtos_inventario ||
        cargo.relatorios_indicadores_kpi ||
        cargo.relatorios_cadastramento_clientes ||
        cargo.relatorios_produtos_setores ||
        cargo.relatorios_produtos_colaborador ||
        cargo.relatorios_relatorio_cartoes ||
        cargo.relatorios_relatorio_fluxo_caixa ||
        cargo.relatorios_relatorio_scores ||
        cargo.sistema_vendas_nao_finalizadas ||
        cargo.sistema_itens_vendas_nao_finalizadas ||
        cargo.relatorio_colaborador_fardamento;
      const showGlobal =
        cargo.global_relatorios_mensal ||
        cargo.global_relatorios_anual ||
        cargo.global_relatorios_produtos ||
        cargo.global_relatorios_clientes ||
        cargo.global_inventario ||
        cargo.global_relatorios_indicadores_kpi ||
        cargo.global_relatorios_cadastro_clientes ||
        cargo.global_relatorios_produtos_local ||
        cargo.global_relatorios_produtos_colaborador ||
        cargo.global_relatorio_colaborador_fardamento;
      const showSistema =
        cargo.gerencial_adms ||
        cargo.mais_opcoes_meios_pagamentos ||
        cargo.mais_opcoes_unidades ||
        cargo.mais_opcoes_cargos ||
        cargo.sistema_pergunta ||
        cargo.sistema_categoria_pergunta ||
        cargo.sistema_categoria_frente_caixa ||
        cargo.sistema_categorias_problemas_despesas ||
        cargo.sistema_videos_tutoriais ||
        cargo.sistema_estados_uf ||
        cargo.sistema_empresas ||
        cargo.sistema_tabela_ibtp ||
        cargo.config;
      const showContabilidade =
        cargo.contabilidade_colaboradores ||
        cargo.contabilidade_funcoes_colaboradores ||
        cargo.contabilidade_checagem_fardamento ||
        cargo.contabilidade_fardamentos ||
        cargo.contabilidade_tamanho_fardamento ||
        cargo.contabilidade_qualidades ||
        cargo.contabilidade_feriados ||
        cargo.contabilidade_desconto_funcionario ||
        cargo.contabilidade_categoria_desconto_funcionario ||
        cargo.sistema_grupo_colaborador ||
        cargo.sistema_equipe_colaborador ||
        cargo.sistema_codinomes_colaborador ||
        cargo.sistema_departamento_colaborador ||
        //cargo.sistema_pontos_colaborador ||
        //cargo.sistema_compensacao_feriados ||
        cargo.sistema_horas_extras ||
        //cargo.sistema_folha_pagamentos ||
        cargo.contabilidade_defeitos;
      const showDevelopment =
        cargo.development_features || cargo.development_softwares;
      const showConferencias = cargo.conferencias_freezers;
      const showTelevendas =
        cargo.mais_opcoes_categorias_clientes || cargo.gerencial_clientes;
      const showInsumos =
        cargo.fornecimento_produtos_insumos ||
        cargo.fornecimento_compras_insumos;
      const showMalote =
        cargo.malote_malotes ||
        cargo.malote_submalotes ||
        cargo.malote_sangrias ||
        cargo.malote_trocos ||
        cargo.malote_sobras ||
        cargo.malote_sinistros ||
        cargo.sistema_lixeira ||
        cargo.sistema_tela_transportadores ||
        cargo.malote_conta_gerencial;
      const showEquipamentos = cargo.equipamentos_freezers;
      const showColetor =
        cargo.coletor_solicitacao_impressao ||
        cargo.coletor_conferencia_temperatura_freezer;
      const showProcessos = cargo.sistema_atividades_processos;
      const showDesenvolvedor = cargo.sistema_logs_tasks;

      return {
        gerencial_dashboard: !!cargo.gerencial_dashboard,
        gerencial_vendas: !!cargo.gerencial_vendas,
        gerencial_devolucoes: !!cargo.gerencial_devolucoes,
        gerencial_pedidos: !!cargo.gerencial_pedidos,
        gerencial_compras: !!cargo.gerencial_compras,
        gerencial_clientes: !!cargo.gerencial_clientes,
        gerencial_fornecedores: !!cargo.gerencial_fornecedores,
        gerencial_adms: !!cargo.gerencial_adms,
        gerencial_setores: !!cargo.gerencial_setores,
        produtos_produtos: !!cargo.produtos_produtos,
        produtos_familias: !!cargo.produtos_familias,
        produtos_inventario: !!cargo.produtos_inventario,
        //produtos_ofertas: !!cargo.produtos_ofertas,
        produtos_categorias: !!cargo.produtos_categorias,
        produtos_taras: !!cargo.produtos_taras,
        produtos_baixar: !!cargo.produtos_baixar,
        produtos_locais_setores: !!cargo.produtos_locais_setores,
        produtos_impostos: !!cargo.produtos_impostos,
        produtos_tabela_lucro: !!cargo.produtos_tabela_lucro,
        conferencias_sugestao_produtos: !!cargo.conferencias_sugestao_produtos,
        financeiro_dashboard: !!cargo.financeiro_dashboard,
        financeiro_sessoes: !!cargo.financeiro_sessoes,
        financeiro_contas: !!cargo.financeiro_contas,
        financeiro_cartoes_credito: !!cargo.financeiro_cartoes_credito,
        financeiro_transacoes: !!cargo.financeiro_transacoes,
        financeiro_pix: !!cargo.financeiro_pix,
        financeiro_nfes: !!cargo.financeiro_nfes,
        financeiro_tela_reajustes: !!cargo.financeiro_tela_reajustes,
        financeiro_futurologia: !!cargo.financeiro_futurologia,
        financeiro_tela_tranferencias: !!cargo.financeiro_tela_tranferencias,
        financeiro_transacoes_simuladas: !!cargo.financeiro_transacoes_simuladas,
        financeiro_vendas_faturadas: !!cargo.financeiro_vendas_faturadas,
        televendas_clientes: !!cargo.televendas_clientes,
        relatorios_mensal: !!cargo.relatorios_mensal,
        relatorios_anual: !!cargo.relatorios_anual,
        relatorios_produtos: !!cargo.relatorios_produtos,
        relatorios_clientes: !!cargo.relatorios_clientes,
        relatorios_indicadores_kpi: !!cargo.relatorios_indicadores_kpi,
        relatorios_cadastramento_clientes: !!cargo.relatorios_cadastramento_clientes,
        relatorios_produtos_setores: !!cargo.relatorios_produtos_setores,
        relatorios_produtos_colaborador: !!cargo.relatorios_produtos_colaborador,
        relatorio_colaborador_fardamento: !!cargo.relatorio_colaborador_fardamento,
        global_relatorios_mensal: !!cargo.global_relatorios_mensal,
        global_relatorios_anual: !!cargo.global_relatorios_anual,
        global_relatorios_produtos: !!cargo.global_relatorios_produtos,
        global_relatorios_clientes: !!cargo.global_relatorios_clientes,
        global_relatorios_indicadores_kpi: !!cargo.global_relatorios_indicadores_kpi,
        global_relatorios_cadastro_clientes: !!cargo.global_relatorios_cadastro_clientes,
        global_relatorios_produtos_local: !!cargo.global_relatorios_produtos_local,
        global_relatorios_produtos_colaborador: !!cargo.global_relatorios_produtos_colaborador,
        global_relatorio_colaborador_fardamento: !!cargo.global_relatorio_colaborador_fardamento,
        global_inventario: !!cargo.global_inventario,
        mais_opcoes_meios_pagamentos: !!cargo.mais_opcoes_meios_pagamentos,
        mais_opcoes_unidades: !!cargo.mais_opcoes_unidades,
        mais_opcoes_categorias_transacoes: !!cargo.mais_opcoes_categorias_transacoes,
        mais_opcoes_categorias_clientes: !!cargo.mais_opcoes_categorias_clientes,
        mais_opcoes_cargos: !!cargo.mais_opcoes_cargos,
        mais_opcoes_funcao_funcionario_fornecedor: !!cargo.mais_opcoes_funcao_funcionario_fornecedor,
        mais_opcoes_banco_fornecedores: !!cargo.mais_opcoes_banco_fornecedores,
        contabilidade_colaboradores: !!cargo.contabilidade_colaboradores,
        contabilidade_funcoes_colaboradores: !!cargo.contabilidade_funcoes_colaboradores,
        contabilidade_checagem_fardamento: !!cargo.contabilidade_checagem_fardamento,
        contabilidade_fardamentos: !!cargo.contabilidade_fardamentos,
        contabilidade_tamanho_fardamento: !!cargo.contabilidade_tamanho_fardamento,
        contabilidade_qualidades: !!cargo.contabilidade_qualidades,
        contabilidade_defeitos: !!cargo.contabilidade_defeitos,
        contabilidade_feriados: !!cargo.contabilidade_feriados,
        development_features: !!cargo.development_features,
        development_versoes: !!cargo.development_versoes,
        development_softwares: !!cargo.development_softwares,
        conferencias_estoque: !!cargo.conferencias_estoque,
        conferencias_impressoes: !!cargo.conferencias_impressoes,
        conferencias_freezers: !!cargo.conferencias_freezers,
        equipamentos_freezers: !!cargo.equipamentos_freezers,
        televendas_aniversariantes: !!cargo.televendas_aniversariantes,
        fornecimento_produtos_insumos: !!cargo.fornecimento_produtos_insumos,
        fornecimento_compras_insumos: !!cargo.fornecimento_compras_insumos,
        config: !!cargo.config,
        sistema_pergunta: !!cargo.sistema_pergunta,
        sistema_categoria_pergunta: !!cargo.sistema_categoria_pergunta,
        contabilidade_desconto_funcionario: !!cargo.contabilidade_desconto_funcionario,
        contabilidade_categoria_desconto_funcionario: !!cargo.contabilidade_categoria_desconto_funcionario,
        malote_malotes: !!cargo.malote_malotes,
        malote_submalotes: !!cargo.malote_submalotes,
        malote_sangrias: !!cargo.malote_sangrias,
        malote_trocos: !!cargo.malote_trocos,
        malote_sobras: !!cargo.malote_sobras,
        malote_sinistros: !!cargo.malote_sinistros,
        malote_conta_gerencial: !!cargo.malote_conta_gerencial,
        sistema_categoria_frente_caixa: !!cargo.sistema_categoria_frente_caixa,
        fornecimento_fornecedores_insumos: !!cargo.fornecimento_fornecedores_insumos,
        fornecimento_fornecedores_servicos: !!cargo.fornecimento_fornecedores_servicos,
        fornecimento_fornecedores_qualquer: !!cargo.fornecimento_fornecedores_qualquer,
        sistema_cotacoes_templates: !!cargo.sistema_cotacoes_templates,
        relatorios_relatorio_cartoes: !!cargo.relatorios_relatorio_cartoes,
        relatorios_relatorio_fluxo_caixa: !!cargo.relatorios_relatorio_fluxo_caixa,
        relatorios_relatorio_scores: !!cargo.relatorios_relatorio_scores,
        sistema_data_comemorativa: !!cargo.sistema_data_comemorativa,
        sistema_categorias_problemas_despesas: !!cargo.sistema_categorias_problemas_despesas,
        sistema_contas_tabela: !!cargo.sistema_contas_tabela,
        sistema_transacoes_conferencias: !!cargo.sistema_transacoes_conferencias,
        sistema_despesas_agendamentos: !!cargo.sistema_despesas_agendamentos,
        sistema_caixas_fc: !!cargo.sistema_caixas_fc,
        sistema_conferencia_caixaria: !!cargo.sistema_conferencia_caixaria,
        coletor_solicitacao_impressao: !!cargo.coletor_solicitacao_impressao,
        coletor_conferencia_temperatura_freezer: !!cargo.coletor_conferencia_temperatura_freezer,
        sistema_grupo_colaborador: !!cargo.sistema_grupo_colaborador,
        sistema_equipe_colaborador: !!cargo.sistema_equipe_colaborador,
        sistema_codinomes_colaborador: !!cargo.sistema_codinomes_colaborador,
        pessoal_turnos: !!cargo.pessoal_turnos,
        sistema_atividades_processos: !!cargo.sistema_atividades_processos,
        sistema_departamento_colaborador: !!cargo.sistema_departamento_colaborador,
        //sistema_pontos_colaborador: !!cargo.sistema_pontos_colaborador,
        //sistema_compensacao_feriados: !!cargo.sistema_compensacao_feriados,
        sistema_vendas_nao_finalizadas: !!cargo.sistema_vendas_nao_finalizadas,
        sistema_itens_vendas_nao_finalizadas: !!cargo.sistema_itens_vendas_nao_finalizadas,
        sistema_lixeira: !!cargo.sistema_lixeira,
        sistema_tela_transportadores: !!cargo.sistema_tela_transportadores,
        sistema_videos_tutoriais: !!cargo.sistema_videos_tutoriais,
        sistema_estados_uf: !!cargo.sistema_estados_uf,
        sistema_conferencia_maquineta: !!cargo.sistema_conferencia_maquineta,
        sistema_conferencia_grandes_pesagens: !!cargo.sistema_conferencia_grandes_pesagens,
        sistema_historico_precificacao: !!cargo.sistema_historico_precificacao,
        sistema_transacoes_especiais: !!cargo.sistema_transacoes_especiais,
        sistema_verbas_produtos: !!cargo.sistema_verbas_produtos,
        sistema_horas_extras: !!cargo.sistema_horas_extras,
        sistema_liberacao_de_precos: !!cargo.sistema_liberacao_de_precos,
        sistema_oferta_zerar_estoque: !!cargo.sistema_oferta_zerar_estoque,
        sistema_jornal_de_ofertas: !!cargo.sistema_jornal_de_ofertas,
        //sistema_folha_pagamentos: !!cargo.sistema_folha_pagamentos,
        sistema_empresas: !!cargo.sistema_empresas,
        sistema_ratoeira: !!cargo.sistema_ratoeira,
        sistema_credito_devolucao: !!cargo.sistema_credito_devolucao,
        sistema_nfe_compra: !!cargo.sistema_nfe_compra,
        sistema_nfe_compra_problematicas: !!cargo.sistema_nfe_compra_problematicas,
        sistema_pedidos_liberacoes: !!cargo.sistema_pedidos_liberacoes,
        sistema_aprovacoes_pedidos_liberacoes: !!cargo.sistema_aprovacoes_pedidos_liberacoes,
        comprador_configuracoes_produtos: !!cargo.comprador_configuracoes_produtos,
        sistema_tabela_ibtp: !!cargo.sistema_tabela_ibtp,
        sistema_logs_tasks: !!cargo.sistema_logs_tasks,
        sistema_tela_validade: !!cargo.sistema_tela_validade,
        sistema_sugestao_pre_compra: !!cargo.sistema_sugestao_pre_compra,
        sistema_tela_representantes: !!cargo.sistema_tela_representantes,
        showPessoal,
        showComprador,
        showVendas,
        showCompras,
        showProduto,
        showFinanceiro,
        showRelatorio,
        showSistema,
        showContabilidade,
        showDevelopment,
        showConferencias,
        showTelevendas,
        showEquipamentos,
        showGlobal,
        showInsumos,
        showMalote,
        showColetor,
        showProcessos,
        showDesenvolvedor,
      };
    }
    return {
      gerencial_dashboard: valorDefault,
      gerencial_vendas: valorDefault,
      gerencial_devolucoes: valorDefault,
      gerencial_pedidos: valorDefault,
      gerencial_compras: valorDefault,
      gerencial_clientes: valorDefault,
      gerencial_fornecedores: valorDefault,
      gerencial_adms: valorDefault,
      gerencial_setores: valorDefault,
      produtos_produtos: valorDefault,
      produtos_familias: valorDefault,
      produtos_inventario: valorDefault,
      //produtos_ofertas: valorDefault,
      produtos_categorias: valorDefault,
      produtos_locais_setores: valorDefault,
      produtos_taras: valorDefault,
      produtos_baixar: valorDefault,
      produtos_impostos: valorDefault,
      produtos_tabela_lucro: valorDefault,
      conferencias_sugestao_produtos: valorDefault,
      financeiro_dashboard: valorDefault,
      financeiro_sessoes: valorDefault,
      financeiro_contas: valorDefault,
      financeiro_cartoes_credito: valorDefault,
      financeiro_transacoes: valorDefault,
      financeiro_pix: valorDefault,
      financeiro_futurologia: valorDefault,
      financeiro_nfes: valorDefault,
      financeiro_tela_tranferencias: valorDefault,
      financeiro_tela_reajustes: valorDefault,
      financeiro_transacoes_simuladas: valorDefault,
      financeiro_vendas_faturadas: valorDefault,
      televendas_clientes: valorDefault,
      relatorios_mensal: valorDefault,
      relatorios_anual: valorDefault,
      relatorios_produtos: valorDefault,
      relatorios_clientes: valorDefault,
      relatorios_indicadores_kpi: valorDefault,
      relatorios_cadastramento_clientes: valorDefault,
      relatorios_produtos_setores: valorDefault,
      relatorios_produtos_colaborador: valorDefault,
      relatorio_colaborador_fardamento: valorDefault,
      global_relatorios_mensal: valorDefault,
      global_relatorios_anual: valorDefault,
      global_relatorios_produtos: valorDefault,
      global_relatorios_clientes: valorDefault,
      global_relatorios_indicadores_kpi: valorDefault,
      global_relatorios_cadastro_clientes: valorDefault,
      global_relatorios_produtos_local: valorDefault,
      global_relatorios_produtos_colaborador: valorDefault,
      global_relatorio_colaborador_fardamento: valorDefault,
      global_inventario: valorDefault,
      mais_opcoes_meios_pagamentos: valorDefault,
      mais_opcoes_unidades: valorDefault,
      mais_opcoes_categorias_transacoes: valorDefault,
      mais_opcoes_categorias_clientes: valorDefault,
      mais_opcoes_cargos: valorDefault,
      mais_opcoes_banco_fornecedores: valorDefault,
      mais_opcoes_funcao_funcionario_fornecedor: valorDefault,
      contabilidade_colaboradores: valorDefault,
      contabilidade_funcoes_colaboradores: valorDefault,
      contabilidade_checagem_fardamento: valorDefault,
      contabilidade_fardamentos: valorDefault,
      contabilidade_tamanho_fardamento: valorDefault,
      contabilidade_qualidades: valorDefault,
      contabilidade_defeitos: valorDefault,
      contabilidade_feriados: valorDefault,
      development_features: valorDefault,
      development_versoes: valorDefault,
      development_softwares: valorDefault,
      conferencias_estoque: valorDefault,
      conferencias_impressoes: valorDefault,
      conferencias_freezers: valorDefault,
      equipamentos_freezers: valorDefault,
      televendas_aniversariantes: valorDefault,
      fornecimento_produtos_insumos: valorDefault,
      fornecimento_compras_insumos: valorDefault,
      config: valorDefault,
      sistema_pergunta: valorDefault,
      sistema_categoria_pergunta: valorDefault,
      contabilidade_desconto_funcionario: valorDefault,
      contabilidade_categoria_desconto_funcionario: valorDefault,
      malote_malotes: valorDefault,
      malote_submalotes: valorDefault,
      malote_sangrias: valorDefault,
      malote_trocos: valorDefault,
      malote_sobras: valorDefault,
      malote_sinistros: valorDefault,
      malote_conta_gerencial: valorDefault,
      sistema_categoria_frente_caixa: valorDefault,
      fornecimento_fornecedores_insumos: valorDefault,
      fornecimento_fornecedores_servicos: valorDefault,
      fornecimento_fornecedores_qualquer: valorDefault,
      sistema_cotacoes_templates: valorDefault,
      relatorios_relatorio_cartoes: valorDefault,
      relatorios_relatorio_fluxo_caixa: valorDefault,
      relatorios_relatorio_scores: valorDefault,
      sistema_data_comemorativa: valorDefault,
      sistema_categorias_problemas_despesas: valorDefault,
      sistema_contas_tabela: valorDefault,
      sistema_transacoes_conferencias: valorDefault,
      sistema_despesas_agendamentos: valorDefault,
      sistema_caixas_fc: valorDefault,
      sistema_conferencia_caixaria: valorDefault,
      coletor_solicitacao_impressao: valorDefault,
      coletor_conferencia_temperatura_freezer: valorDefault,
      sistema_grupo_colaborador: valorDefault,
      sistema_equipe_colaborador: valorDefault,
      sistema_codinomes_colaborador: valorDefault,
      pessoal_turnos: valorDefault,
      sistema_atividades_processos: valorDefault,
      sistema_departamento_colaborador: valorDefault,
      //sistema_pontos_colaborador: valorDefault,
      //sistema_compensacao_feriados: valorDefault,
      sistema_vendas_nao_finalizadas: valorDefault,
      sistema_itens_vendas_nao_finalizadas: valorDefault,
      sistema_lixeira: valorDefault,
      sistema_tela_transportadores: valorDefault,
      sistema_videos_tutoriais: valorDefault,
      sistema_estados_uf: valorDefault,
      sistema_conferencia_maquineta: valorDefault,
      sistema_conferencia_grandes_pesagens: valorDefault,
      sistema_historico_precificacao: valorDefault,
      sistema_transacoes_especiais: valorDefault,
      sistema_verbas_produtos: valorDefault,
      sistema_horas_extras: valorDefault,
      sistema_liberacao_de_precos: valorDefault,
      sistema_oferta_zerar_estoque: valorDefault,
      sistema_jornal_de_ofertas: valorDefault,
      //sistema_folha_pagamentos: valorDefault,
      sistema_empresas: valorDefault,
      sistema_ratoeira: valorDefault,
      sistema_credito_devolucao: valorDefault,
      sistema_nfe_compra: valorDefault,
      sistema_nfe_compra_problematicas: valorDefault,
      sistema_pedidos_liberacoes: valorDefault,
      sistema_aprovacoes_pedidos_liberacoes: valorDefault,
      comprador_configuracoes_produtos: valorDefault,
      sistema_tabela_ibtp: valorDefault,
      sistema_logs_tasks: valorDefault,
      sistema_tela_validade: valorDefault,
      sistema_sugestao_pre_compra: valorDefault,
      sistema_tela_representantes: valorDefault,
      showPessoal: valorDefault,
      showComprador: valorDefault,
      showVendas: valorDefault,
      showCompras: valorDefault,
      showProduto: valorDefault,
      showFinanceiro: valorDefault,
      showRelatorio: valorDefault,
      showSistema: valorDefault,
      showContabilidade: valorDefault,
      showDevelopment: valorDefault,
      showConferencias: valorDefault,
      showTelevendas: valorDefault,
      showEquipamentos: valorDefault,
      showGlobal: valorDefault,
      showInsumos: valorDefault,
      showMalote: valorDefault,
      showColetor: valorDefault,
      showProcessos: valorDefault,
      showDesenvolvedor: valorDefault,
    };
  }

  const {
    gerencial_vendas,
    gerencial_devolucoes,
    gerencial_pedidos,
    gerencial_compras,
    gerencial_clientes,
    gerencial_fornecedores,
    gerencial_adms,
    gerencial_setores,
    produtos_produtos,
    produtos_familias,
    produtos_inventario,
    //produtos_ofertas,
    produtos_categorias,
    produtos_taras,
    produtos_baixar,
    produtos_locais_setores,
    produtos_tabela_lucro,
    conferencias_sugestao_produtos,
    financeiro_dashboard,
    financeiro_sessoes,
    financeiro_contas,
    financeiro_cartoes_credito,
    financeiro_transacoes,
    financeiro_pix,
    financeiro_futurologia,
    financeiro_nfes,
    financeiro_tela_tranferencias,
    financeiro_tela_reajustes,
    financeiro_transacoes_simuladas,
    financeiro_vendas_faturadas,
    relatorios_mensal,
    relatorios_anual,
    relatorios_produtos,
    relatorios_clientes,
    relatorios_indicadores_kpi,
    relatorios_cadastramento_clientes,
    relatorios_produtos_setores,
    relatorio_colaborador_fardamento,
    relatorios_produtos_colaborador,
    global_relatorios_mensal,
    global_relatorios_anual,
    global_relatorios_produtos,
    global_relatorios_clientes,
    global_relatorios_indicadores_kpi,
    global_relatorios_cadastro_clientes,
    global_relatorios_produtos_local,
    global_relatorios_produtos_colaborador,
    global_relatorio_colaborador_fardamento,
    global_inventario,
    mais_opcoes_meios_pagamentos,
    mais_opcoes_unidades,
    mais_opcoes_categorias_transacoes,
    mais_opcoes_categorias_clientes,
    mais_opcoes_cargos,
    mais_opcoes_banco_fornecedores,
    mais_opcoes_funcao_funcionario_fornecedor,
    contabilidade_colaboradores,
    contabilidade_funcoes_colaboradores,
    contabilidade_checagem_fardamento,
    contabilidade_defeitos,
    contabilidade_fardamentos,
    contabilidade_feriados,
    contabilidade_qualidades,
    contabilidade_tamanho_fardamento,
    development_features,
    development_softwares,
    conferencias_estoque,
    conferencias_impressoes,
    conferencias_freezers,
    equipamentos_freezers,
    sistema_pergunta,
    sistema_categoria_pergunta,
    contabilidade_desconto_funcionario,
    contabilidade_categoria_desconto_funcionario,
    malote_malotes,
    malote_submalotes,
    malote_sangrias,
    malote_trocos,
    malote_sobras,
    malote_sinistros,
    malote_conta_gerencial,
    sistema_categoria_frente_caixa,
    fornecimento_fornecedores_insumos,
    fornecimento_fornecedores_servicos,
    fornecimento_fornecedores_qualquer,
    sistema_cotacoes_templates,
    relatorios_relatorio_cartoes,
    relatorios_relatorio_fluxo_caixa,
    relatorios_relatorio_scores,
    sistema_categorias_problemas_despesas,
    sistema_contas_tabela,
    sistema_transacoes_conferencias,
    sistema_despesas_agendamentos,
    sistema_caixas_fc,
    sistema_conferencia_caixaria,
    coletor_conferencia_temperatura_freezer,
    coletor_solicitacao_impressao,
    config,
    sistema_grupo_colaborador,
    sistema_equipe_colaborador,
    sistema_codinomes_colaborador,
    pessoal_turnos,
    sistema_atividades_processos,
    sistema_departamento_colaborador,
    //sistema_pontos_colaborador,
    //sistema_compensacao_feriados,
    sistema_vendas_nao_finalizadas,
    sistema_itens_vendas_nao_finalizadas,
    sistema_lixeira,
    sistema_tela_transportadores,
    sistema_videos_tutoriais,
    sistema_estados_uf,
    sistema_conferencia_maquineta,
    sistema_conferencia_grandes_pesagens,
    sistema_historico_precificacao,
    sistema_transacoes_especiais,
    sistema_verbas_produtos,
    sistema_horas_extras,
    sistema_liberacao_de_precos,
    sistema_oferta_zerar_estoque,
    sistema_jornal_de_ofertas,
    //sistema_folha_pagamentos,
    sistema_empresas,
    sistema_ratoeira,
    sistema_credito_devolucao,
    sistema_nfe_compra,
    sistema_nfe_compra_problematicas,
    sistema_pedidos_liberacoes,
    sistema_aprovacoes_pedidos_liberacoes,
    comprador_configuracoes_produtos,
    sistema_tabela_ibtp,
    sistema_logs_tasks,
    sistema_tela_validade,
    sistema_sugestao_pre_compra,
    sistema_tela_representantes,
    showPessoal,
    showVendas,
    showComprador,
    showCompras,
    showProduto,
    showFinanceiro,
    showRelatorio,
    showSistema,
    showContabilidade,
    showDevelopment,
    showConferencias,
    showTelevendas,
    showEquipamentos,
    showGlobal,
    showMalote,
    showColetor,
    showProcessos,
    showDesenvolvedor,
  } = getPermissoesDestrinchadas();

  const isGestor = colaborador ? colaborador.is_gestor : false;

  return (
    <div style={{ marginTop: '20px' }}>
      {open ? (
        <div className={classes.itemLogo}>
          {logoApp && (
            <img src={logoApp} className={classes.logo} alt="Logo do site" />
          )}
          <div className={classes.content}>{labelApp}</div>
        </div>
      ) : null}

      <div style={{ margin: '0px 0px 10px 10px' }}>
        {showFinanceiro ? (
          <TipoTransacaoSelect open={open} handleChange={create} />
        ) : null}
      </div>

      <SubMenu
        handleToggle={() => handleToggle('menuPessoal')}
        isOpen={menuOpen.menuPessoal}
        sidebarIsOpen={open}
        name="Pessoal"
        icon={<PagesIcon className={classes.icon} />}
      >
        <StyledMenuItemLink
          key="tabela-escalas"
          to="/tabela-escalas"
          primaryText="Escalas"
          leftIcon={<ListAltIcon className={classes.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        {/*hasResource('atividades_processos_pessoal')*/}
        {hasResource('instancias_atividades_processos_colaborador')}
        {isGestor && hasResource('instancias_atividades_processos_gestor')}
        {hasResource('feriados_pessoal')}
        <StyledMenuItemLink
          key="grade-horarios"
          to="/grade-horarios"
          primaryText="Grade de Horários"
          leftIcon={<ListAltIcon className={classes.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        {isGerenteCaixa && hasResource('qrcodes_gerentes')}
        {hasResource('trocos_transportadores')}
      </SubMenu>

      {showComprador ? (
        <SubMenu
          handleToggle={() => handleToggle('menuComprador')}
          isOpen={menuOpen.menuComprador}
          sidebarIsOpen={open}
          name="Comprador"
          icon={<PagesIcon className={classes.icon} />}
        >
          {comprador_configuracoes_produtos &&
            hasResource('configuracoes_produtos')}
          {mais_opcoes_banco_fornecedores && hasResource('bancos_fornecedores')}
          {mais_opcoes_funcao_funcionario_fornecedor &&
            hasResource('funcoes_funcionarios_fornecedores')}
          {sistema_jornal_de_ofertas && hasResource('jornais_de_ofertas')}
          {sistema_cotacoes_templates && hasResource('cotacoes_templates')}
          {sistema_oferta_zerar_estoque &&
            hasResource('ofertas_de_zerar_estoque')}
          {/*        {sistema_sugestao_pre_compra && (
            <StyledMenuItemLink
              key="sugestoes-pre-compra"
              to="/sugestoes-pre-compra"
              primaryText="Sugestões de Pré Compra"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )} */}
          {sistema_sugestao_pre_compra && hasResource('sugestoes_pre_compra')}
          {sistema_tela_representantes &&
            hasResource('representantes-fornecedores')}
        </SubMenu>
      ) : null}

      {showVendas ? (
        <SubMenu
          handleToggle={() => handleToggle('menuVendas')}
          isOpen={menuOpen.menuVendas}
          sidebarIsOpen={open}
          name="Vendas"
          icon={<PagesIcon className={classes.icon} />}
        >
          {gerencial_vendas && hasResource('vendas')}
          {/*gerencial_devolucoes && hasResource('devolucoes')*/}
          {gerencial_devolucoes && hasResource('devolucoes_vendas')}
          {gerencial_pedidos && hasResource('pre_vendas')}
          {sistema_ratoeira && hasResource('ratoeira')}
          {sistema_credito_devolucao && hasResource('creditos_devolucoes')}
          {sistema_pedidos_liberacoes &&
            hasResource('pedidos_liberacao_precos')}
          {sistema_aprovacoes_pedidos_liberacoes &&
            hasResource('solicitacoes_liberacao_precos')}
        </SubMenu>
      ) : null}

      {showCompras ? (
        <SubMenu
          handleToggle={() => handleToggle('menuCompras')}
          isOpen={menuOpen.menuCompras}
          sidebarIsOpen={open}
          name="Fornecimento"
          icon={<PagesIcon className={classes.icon} />}
        >
          {gerencial_compras && hasResource('compras')}
          {sistema_nfe_compra && hasResource('nfe_compras')}
          {sistema_nfe_compra_problematicas &&
            hasResource('nfe_compras_problematicas')}
          {gerencial_fornecedores && hasResource('fornecedores')}
          {fornecimento_fornecedores_insumos &&
            hasResource('fornecedores_insumos')}
          {fornecimento_fornecedores_servicos &&
            hasResource('fornecedores_servicos')}
          {fornecimento_fornecedores_qualquer &&
            hasResource('fornecedores_qualquer')}
        </SubMenu>
      ) : null}

      {showProduto ? (
        <SubMenu
          handleToggle={() => handleToggle('menuProduto')}
          isOpen={menuOpen.menuProduto}
          sidebarIsOpen={open}
          name="Itens de venda"
          icon={<AssignmentIcon className={classes.icon} />}
        >
          {produtos_produtos && hasResource('produtos')}
          {produtos_familias && hasResource('familias_produtos')}
          {produtos_categorias && hasResource('categorias')}
          {produtos_taras && hasResource('taras_balanca')}
          {produtos_tabela_lucro && hasResource('tabela_lucros')}
          {produtos_baixar && (
            <StyledMenuItemLink
              key="baixar"
              to="/baixar"
              primaryText="Baixar"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {produtos_locais_setores && hasResource('locais_setores')}
          {conferencias_sugestao_produtos && hasResource('sugestao_produtos')}
          {gerencial_setores && hasResource('setores')}
          {sistema_conferencia_caixaria &&
            hasResource('produtos-caixaria-verificacao')}

          {/*produtos_ofertas && hasResource('ofertas_jornal_produtos')*/}
          {sistema_liberacao_de_precos && hasResource('liberacoes_de_precos')}

          {sistema_conferencia_grandes_pesagens &&
            hasResource('conferencias_grandes_pesagens')}
          {sistema_historico_precificacao &&
            hasResource('historico_precificacoes')}
          {sistema_verbas_produtos && hasResource('verbas_produtos')}
          {sistema_tela_validade && hasResource('validades-produtos')}
        </SubMenu>
      ) : null}

      {showTelevendas ? (
        <SubMenu
          handleToggle={() => handleToggle('menuTelevendas')}
          isOpen={menuOpen.menuTelevendas}
          sidebarIsOpen={open}
          name="Televendas"
          icon={<AssignmentIcon className={classes.icon} />}
        >
          {gerencial_clientes && hasResource('clientes')}
          {mais_opcoes_categorias_clientes && hasResource('categorias_cliente')}
        </SubMenu>
      ) : null}

      {showProcessos ? (
        <SubMenu
          handleToggle={() => handleToggle('menuProcessos')}
          isOpen={menuOpen.menuProcessos}
          sidebarIsOpen={open}
          name="Processos"
          icon={<AssignmentIcon className={classes.icon} />}
        >
          {sistema_atividades_processos && hasResource('atividades_processos')}
          {sistema_atividades_processos &&
            hasResource('instancias_atividades_processos')}
        </SubMenu>
      ) : null}

      {showFinanceiro ? (
        <SubMenu
          handleToggle={() => handleToggle('menuContabilidade')}
          isOpen={menuOpen.menuContabilidade}
          sidebarIsOpen={open}
          name="Financeiro"
          icon={<MonetizationOnIcon className={classes.icon} />}
        >
          {financeiro_dashboard && (
            <StyledMenuItemLink
              key="dashboard_contabilidade"
              to="/dashboard-contabilidade"
              primaryText="Dashboard"
              leftIcon={<DashboardIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}

          {financeiro_sessoes && hasResource('sessoes')}
          {financeiro_contas && hasResource('contas')}
          {sistema_caixas_fc && hasResource('caixas_fc')}
          {sistema_contas_tabela && hasResource('contas_tabela')}
          {financeiro_nfes && hasResource('parcelas_vendas_nfe_verificado')}
          {financeiro_cartoes_credito && hasResource('cartoes_credito')}
          {financeiro_transacoes && hasResource('transacoes')}
          {sistema_transacoes_conferencias && hasResource('transacoes_limpas')}
          {financeiro_pix && hasResource('transacoes_pix')}
          {financeiro_tela_tranferencias &&
            hasResource('financeiro_tela_transferencias')}
          {financeiro_tela_reajustes &&
            hasResource('financeiro_tela_reajustes')}
          {mais_opcoes_categorias_transacoes &&
            hasResource('categorias_transacao_cards')}
          {financeiro_futurologia && (
            <StyledMenuItemLink
              key="tela_futurologia"
              to="/tela_futurologia"
              primaryText="Futurologia"
              leftIcon={<ImportExportIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {financeiro_transacoes_simuladas &&
            hasResource('transacoes_simuladas')}
          {financeiro_vendas_faturadas && (
            <StyledMenuItemLink
              key="vendas_faturadas"
              to="/vendas_faturadas"
              primaryText="Vendas Faturadas"
              leftIcon={<ImportExportIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {sistema_despesas_agendamentos && (
            <StyledMenuItemLink
              key="despesas_agendadas"
              to="/despesas_agendadas"
              primaryText="Agendamentos"
              leftIcon={<ImportExportIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {sistema_conferencia_maquineta &&
            hasResource('transacoes_conferencia_get_net')}
          {sistema_transacoes_especiais && hasResource('contas_especiais')}
        </SubMenu>
      ) : null}

      {showMalote ? (
        <SubMenu
          handleToggle={() => handleToggle('menuMalote')}
          isOpen={menuOpen.menuMalote}
          sidebarIsOpen={open}
          name="Malotes"
          icon={<AssignmentIcon className={classes.icon} />}
        >
          {malote_malotes && hasResource('malote_malotes')}
          {malote_submalotes && hasResource('malote_submalotes')}
          {malote_sangrias && hasResource('malote_sangrias')}
          {malote_trocos && hasResource('malote_trocos')}
          {malote_sobras && hasResource('malote_sobras')}
          {malote_sinistros && hasResource('malote_sinistros')}
          {malote_conta_gerencial && (
            <StyledMenuItemLink
              key="conta-gerencial"
              to="/conta-gerencial"
              primaryText="Conta Gerencial"
              leftIcon={<ImportExportIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {sistema_lixeira && hasResource('lixeira_malotes')}
        </SubMenu>
      ) : null}

      {showRelatorio ? (
        <SubMenu
          handleToggle={() => handleToggle('menuRelatorio')}
          isOpen={menuOpen.menuRelatorio}
          sidebarIsOpen={open}
          name="Relatórios"
          icon={<AssignmentIcon className={classes.icon} />}
        >
          {relatorios_mensal && (
            <StyledMenuItemLink
              key="relatorio_mensal_vendas"
              to="/relatorio_mensal_vendas"
              primaryText="Mensal"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_anual && (
            <StyledMenuItemLink
              key="relatorio_anual_vendas"
              to="/relatorio_anual_vendas"
              primaryText="Anual"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_produtos && (
            <StyledMenuItemLink
              key="relatorio_produtos"
              to="/relatorio_produtos"
              primaryText="Produtos"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_clientes && (
            <StyledMenuItemLink
              key="relatorio_clientes"
              to="/relatorio_clientes"
              primaryText="Clientes"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_indicadores_kpi && (
            <StyledMenuItemLink
              key="indicadores_kpi"
              to="/indicadores_kpi"
              primaryText="Indicadores kpi"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {produtos_inventario && (
            <StyledMenuItemLink
              key="inventario"
              to="/inventario"
              primaryText="Inventário"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_cadastramento_clientes && (
            <StyledMenuItemLink
              key="relatorio_cadastro_clientes"
              to="/relatorio_cadastro_clientes"
              primaryText="Cadastro Clientes"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_produtos_setores && (
            <StyledMenuItemLink
              key="relatorio_produtos_setores_inventario"
              to="/relatorio_produtos_setores_inventario"
              primaryText="Produtos de um local"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_produtos_colaborador && (
            <StyledMenuItemLink
              key="relatorio_produtos_colaborador_inventario"
              to="/relatorio_produtos_colaborador_inventario"
              primaryText="Produtos colaborador"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorio_colaborador_fardamento && (
            <StyledMenuItemLink
              key="relatorio_produtos_colaborador_fardamento"
              to="/relatorio_produtos_colaborador_fardamento"
              primaryText="Fardamentos Colaborador"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_relatorio_cartoes && (
            <StyledMenuItemLink
              key="relatorio_cartoes"
              to="/relatorio_cartoes"
              primaryText="Relatório Cartões"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_relatorio_fluxo_caixa && (
            <StyledMenuItemLink
              key="relatorio-fluxo-caixa"
              to="/relatorio-fluxo-caixa"
              primaryText="Relatório Fluxo Caixa"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {relatorios_relatorio_scores && (
            <StyledMenuItemLink
              key="relatorio-scores"
              to="/relatorio-scores"
              primaryText="Relatório Scores"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {sistema_vendas_nao_finalizadas && hasResource('vendas_excluidas')}
          {sistema_itens_vendas_nao_finalizadas &&
            hasResource('itens_vendas_excluidas')}
        </SubMenu>
      ) : null}

      {showGlobal ? (
        <SubMenu
          handleToggle={() => handleToggle('menuGlobal')}
          isOpen={menuOpen.menuGlobal}
          sidebarIsOpen={open}
          name="Global"
          icon={<AssignmentIcon className={classes.icon} />}
        >
          {global_relatorios_mensal && (
            <StyledMenuItemLink
              key="relatorio_mensal_vendas"
              to="/relatorio_mensal_vendas"
              primaryText="Relatório Mensal"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_anual && (
            <StyledMenuItemLink
              key="relatorio_anual_vendas"
              to="/relatorio_anual_vendas"
              primaryText="Relatório Anual"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_produtos && (
            <StyledMenuItemLink
              key="relatorio_produtos"
              to="/relatorio_produtos"
              primaryText="Relatório Produtos"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_clientes && (
            <StyledMenuItemLink
              key="relatorio_clientes"
              to="/relatorio_clientes"
              primaryText="Relatório Clientes"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_indicadores_kpi && (
            <StyledMenuItemLink
              key="indicadores_kpi"
              to="/indicadores_kpi"
              primaryText="Indicadores kpi"
              leftIcon={<AttachMoneyIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_inventario && (
            <StyledMenuItemLink
              key="inventario"
              to="/inventario"
              primaryText="Inventário"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_cadastro_clientes && (
            <StyledMenuItemLink
              key="relatorio_cadastro_clientes"
              to="/relatorio_cadastro_clientes"
              primaryText="Cadastro Clientes"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_produtos_local && (
            <StyledMenuItemLink
              key="relatorio_produtos_setores_inventario"
              to="/relatorio_produtos_setores_inventario"
              primaryText="Produtos de um local"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorios_produtos_colaborador && (
            <StyledMenuItemLink
              key="relatorio_produtos_colaborador_inventario"
              to="/relatorio_produtos_colaborador_inventario"
              primaryText="Produtos colaborador"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {global_relatorio_colaborador_fardamento && (
            <StyledMenuItemLink
              key="relatorio_produtos_colaborador_fardamento"
              to="/relatorio_produtos_colaborador_fardamento"
              primaryText="Fardamentos Colaborador"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
        </SubMenu>
      ) : null}

      {showContabilidade ? (
        <SubMenu
          handleToggle={() => handleToggle('menuContabilidadeEmpregos')}
          isOpen={menuOpen.menuContabilidadeEmpregos}
          sidebarIsOpen={open}
          name="Contabilidade"
          icon={<MoreHorizIcon className={classes.icon} />}
        >
          {contabilidade_colaboradores && hasResource('colaboradores')}
          {contabilidade_funcoes_colaboradores &&
            hasResource('funcoes_colaboradores')}
          {contabilidade_checagem_fardamento && hasResource('camisas_checagem')}
          {contabilidade_fardamentos && hasResource('fardamentos')}
          {contabilidade_feriados && hasResource('feriados')}
          {contabilidade_tamanho_fardamento &&
            hasResource('tamanhos_fardamentos')}
          {contabilidade_qualidades && hasResource('qualidades_colaborador')}
          {contabilidade_defeitos && hasResource('defeitos_colaborador')}
          {contabilidade_desconto_funcionario &&
            hasResource('descontos_funcionarios')}
          {contabilidade_categoria_desconto_funcionario &&
            hasResource('categorias_descontos_funcionarios')}
          {sistema_grupo_colaborador && hasResource('grupos_colaborador')}
          {sistema_equipe_colaborador && hasResource('equipes_colaborador')}
          {sistema_codinomes_colaborador &&
            hasResource('codinomes_colaborador')}
          {sistema_departamento_colaborador &&
            hasResource('departamentos_colaborador')}
          {/*sistema_pontos_colaborador && hasResource('pontos_colaborador')*/}
          {/*sistema_compensacao_feriados && hasResource('compensacoes_feriados')*/}
          {sistema_horas_extras && (
            <StyledMenuItemLink
              key="horas-extras"
              to="/horas-extras"
              primaryText="Horas Extras"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {/*sistema_horas_extras && (
            <StyledMenuItemLink
              key="horas-extras-2"
              to="/horas-extras-2"
              primaryText="Horas Extras Teste"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )*/}
          {/*sistema_folha_pagamentos && (
            <StyledMenuItemLink
              key="folha-de-pagamento"
              to="/folha-de-pagamento"
              primaryText="Folha de Pagamento"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )*/}
        </SubMenu>
      ) : null}

      {showEquipamentos ? (
        <SubMenu
          handleToggle={() => handleToggle('menuEquipamentos')}
          isOpen={menuOpen.menuEquipamentos}
          sidebarIsOpen={open}
          name="Equipamentos"
          icon={<BuildIcon className={classes.icon} />}
        >
          {equipamentos_freezers && hasResource('equipamentos_freezer')}
        </SubMenu>
      ) : null}

      {showSistema ? (
        <SubMenu
          handleToggle={() => handleToggle('menuConfig')}
          isOpen={menuOpen.menuConfig}
          sidebarIsOpen={open}
          name="Sistema"
          icon={<MoreHorizIcon className={classes.icon} />}
        >
          {mais_opcoes_meios_pagamentos && hasResource('tipospagamento')}
          {mais_opcoes_unidades && hasResource('unidades')}
          {mais_opcoes_cargos && hasResource('cargos')}
          {gerencial_adms && hasResource('users')}
          {sistema_pergunta && hasResource('perguntas')}
          {sistema_categoria_pergunta && hasResource('categorias_perguntas')}
          {sistema_categoria_frente_caixa &&
            hasResource('categorias_frente_caixas')}
          {sistema_categorias_problemas_despesas &&
            hasResource('categorias-problemas-despesas')}
          {config && (
            <StyledMenuItemLink
              key="config_correto"
              to="/config_correto"
              primaryText="Configurações"
              leftIcon={<ListAltIcon className={classes.icon} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          {sistema_videos_tutoriais && hasResource('videos_tutoriais_fc')}
          {sistema_estados_uf && hasResource('estados')}
          {sistema_empresas && hasResource('empresa-loja')}
          {sistema_tabela_ibtp && hasResource('tabela-ibtp')}
        </SubMenu>
      ) : null}

      {showDevelopment ? (
        <SubMenu
          handleToggle={() => handleToggle('menuDevelopment')}
          isOpen={menuOpen.menuDevelopment}
          sidebarIsOpen={open}
          name="Development"
          icon={<DeveloperModeIcon className={classes.icon} />}
        >
          {development_features && hasResource('features')}
          {development_softwares && hasResource('softwares')}
        </SubMenu>
      ) : null}

      {showConferencias ? (
        <SubMenu
          handleToggle={() => handleToggle('menuConferencia')}
          isOpen={menuOpen.menuConferencia}
          sidebarIsOpen={open}
          name="Conferências"
          icon={<PlaylistAddCheckIcon className={classes.icon} />}
        >
          {conferencias_freezers &&
            hasResource('conferencias_temperatura_freezers')}
        </SubMenu>
      ) : null}

      {showColetor ? (
        <SubMenu
          handleToggle={() => handleToggle('menuColetor')}
          isOpen={menuOpen.menuColetor}
          sidebarIsOpen={open}
          name="Coletor"
          icon={<DeveloperModeIcon className={classes.icon} />}
        >
          {coletor_solicitacao_impressao &&
            hasResource('solicitacoes_impressoes_etiqueta_2')}
          {coletor_conferencia_temperatura_freezer &&
            hasResource('conferencias_temperatura_freezers_2')}
        </SubMenu>
      ) : null}

      {showDesenvolvedor ? (
        <SubMenu
          handleToggle={() => handleToggle('menuDesenvolvedor')}
          isOpen={menuOpen.menuDesenvolvedor}
          sidebarIsOpen={open}
          name="Desenvolvedor"
          icon={<DeveloperModeIcon className={classes.icon} />}
        >
          {sistema_logs_tasks && hasResource('logs-task')}
        </SubMenu>
      ) : null}

      {isXSmall && logout}
      <DialogoTransacao
        ref={refDialogoTransacao}
        handleClose={handleCloseDialogoTransacao}
      />
      <DialogoDespesaCartaoCredito
        ref={refDialogoDespesaCartao}
        handleClose={handleCloseDialogoTransacao}
      />
    </div>
  );
};

export default MenuLocal;
