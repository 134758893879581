import React from 'react';
import { Route } from 'react-router-dom';

import Agendamentos from './Pages/Agendamentos';
import BaixarArquivos from './Pages/BaixarArquivos';
import Config from './Pages/Config';
import ContaGerencial from './Pages/ContaGerencial';
import CotacaoPrecosProdutos from './Pages/CotacaoPrecosProdutos';
import Cotacoes from './Pages/Cotacoes';
import DashboardContabilidade from './Pages/DashboardContabilidade';
import Escalas from './Pages/Escalas';
import FolhaPagamento from './Pages/FolhaPagamento';
import GradeHorarios from './Pages/GradeHorarios';
//import HorasExtras2 from './Pages/HorasExtras/index';
import HorasExtras from './Pages/HorasExtras/index3';
import IndicadoresKPI from './Pages/IndicadoresKPI';
import Inventario from './Pages/inventario';
import { LerPlanilhaCartoes } from './Pages/LerPlanilhaCartoes';
import RelatorioCadastroClientes from './Pages/RelatorioCadastroClientes';
import RelatorioCartoes from './Pages/RelatorioCartoes';
import RelatorioClientes from './Pages/RelatorioClientes';
import RelatorioFardamentoColaborador from './Pages/RelatorioFardamentosColaborador';
import RelatorioFluxoCaixa from './Pages/RelatorioFluxoCaixa';
import RelatorioFluxoCaixaProdutos from './Pages/RelatorioFluxoCaixaProdutos';
import RelatorioProdutos from './Pages/RelatorioProdutos';
import RelatorioProdutosColaborador from './Pages/RelatorioProdutosColaborador';
import RelatorioProdutosSetores from './Pages/RelatorioProdutosSetores';
import RelatorioScores from './Pages/RelatorioScores';
import RelatorioVendasAnual from './Pages/RelatorioVendaAnual';
import RelatorioVendasMensal from './Pages/RelatorioVendaMensal';
import ResumoMercadoriasCliente from './Pages/ResumoMercadoriasCliente';
//import SugestoesPreCompra from './Pages/SugestoesPreCompra';
import TelaFuturologia from './Pages/TelaFuturologia';
import VendasFaturadas from './Pages/TelaVendasFaturadas';
import TesteLeituraXml from './Pages/TesteLeituraXml';
import TestePrecificacao from './Pages/TestePrecificacao';
//import Turnos from './Pages/Turnos';

export default [
  <Route exact path="/inventario" render={() => <Inventario />} />,
  <Route
    exact
    path="/dashboard-contabilidade"
    render={() => <DashboardContabilidade />}
  />,
  <Route
    exact
    path="/relatorio_mensal_vendas"
    render={() => <RelatorioVendasMensal />}
  />,
  <Route
    exact
    path="/relatorio_anual_vendas"
    render={() => <RelatorioVendasAnual />}
  />,
  <Route
    exact
    path="/relatorio_produtos"
    render={() => <RelatorioProdutos />}
  />,
  <Route
    exact
    path="/relatorio_clientes"
    render={() => <RelatorioClientes />}
  />,
  <Route exact path="/indicadores_kpi" render={() => <IndicadoresKPI />} />,
  <Route exact path="/baixar" render={() => <BaixarArquivos />} />,
  <Route
    exact
    path="/teste_precificacao"
    render={() => <TestePrecificacao />}
  />,
  <Route
    exact
    path="/relatorio_cadastro_clientes"
    render={() => <RelatorioCadastroClientes />}
  />,
  <Route
    exact
    path="/relatorio_produtos_setores_inventario"
    render={() => <RelatorioProdutosSetores />}
  />,
  <Route
    exact
    path="/relatorio_produtos_colaborador_inventario"
    render={() => <RelatorioProdutosColaborador />}
  />,
  <Route exact path="/tela_futurologia" render={() => <TelaFuturologia />} />,
  <Route
    exact
    path="/relatorio_produtos_colaborador_fardamento"
    render={() => <RelatorioFardamentoColaborador />}
  />,
  <Route exact path="/config_correto" render={() => <Config />} />,
  <Route exact path="/vendas_faturadas" render={() => <VendasFaturadas />} />,
  <Route exact path="/conta-gerencial" render={() => <ContaGerencial />} />,
  <Route
    exact
    path="/relatorio-fluxo-caixa-produtos"
    render={() => <RelatorioFluxoCaixaProdutos />}
  />,
  <Route
    exact
    path="/cotacao-precos-produtos"
    render={() => <CotacaoPrecosProdutos />}
  />,
  <Route
    exact
    path="/resumo-mercadorias-cliente"
    render={() => <ResumoMercadoriasCliente />}
  />,
  <Route exact path="/relatorio_cartoes" render={() => <RelatorioCartoes />} />,
  <Route
    exact
    path="/relatorio-fluxo-caixa"
    render={() => <RelatorioFluxoCaixa />}
  />,
  <Route exact path="/relatorio-scores" render={() => <RelatorioScores />} />,
  <Route exact path="/cotacoes" render={() => <Cotacoes />} />,
  <Route exact path="/despesas_agendadas" render={() => <Agendamentos />} />,
  <Route exact path="/tabela-escalas" render={() => <Escalas />} />,
  <Route exact path="/grade-horarios" render={() => <GradeHorarios />} />,
  <Route
    exact
    path="/ler-planilha-cartoes"
    render={() => <LerPlanilhaCartoes />}
  />,
  <Route exact path="/horas-extras" render={() => <HorasExtras />} />,
  <Route exact path="/folha-de-pagamento" render={() => <FolhaPagamento />} />,
  <Route exact path="/teste-leitura-xml" render={() => <TesteLeituraXml />} />,
  /*   <Route
    exact
    path="/sugestoes-pre-compra"
    render={() => <SugestoesPreCompra />}
  />, */
  //<Route exact path="/horas-extras-2" render={() => <HorasExtras2 />} />,
];
